import React, { ComponentState, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { pieChartModel } from 'src/dataModels/docListModel';
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import CustomSkeleton from "src/control-components/skeleton/skeleton";
import CommonListFilterItem from './common-list-filter-item';




export const DocumentByTemplate = () => {
    const [isShowItems, setShowItems] = useState(false);
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const [billingData, setBillingData] = useState<pieChartModel[]>([])
    const { documentBillingByTemplate } = state['DocumentReducer'];
    useEffect(() => {
        setBillingData([]);
        if (documentBillingByTemplate && documentBillingByTemplate?.resultData) {
          const sortedArray=  documentBillingByTemplate?.resultData?.sort(function (a, b) {
                return  b.totDocs - a.totDocs;
            });
            sortedArray?.map((element, index) => {
                const label = `Docs:${element?.totDocs}}`
                var obj: pieChartModel = {
                    id: element?.templateId, 
                    label: `${element?.templateName}`, 
                    value: element?.totDocs,
                    // color: element?.color, 
                    info: label,
                    totalBilled: '',
                    count: element?.totDocs
                }
                setBillingData(oldArray => [...oldArray, obj]);
            })
        }
        setShowItems(true)
    }, [documentBillingByTemplate])


    const mostDocumentsBilling = billingData[0];
    const totalValue = billingData.reduce((sum, item) => sum + item.count, 0);

    const MyHeader = () => {
        return (
          <ListViewHeader
            style={{
              color: "#000000",
              fontSize: 30,
            }}
            className="px-3 py-2 "
          >
            <div className="row border-bottom align-middle">
              <div className="col-6">
                <h2 className="f-14">Template</h2>
              </div>
              <div className="col-6">
                <h2 className="f-14"># of Docs</h2>
              </div>
            </div>
          </ListViewHeader>
        );
      };
      const MyItemRender = (props) => {
        let item = props.dataItem;
        return (
          <div
            className="row py-2 border-bottom align-middle"
            style={{ margin: 0 }}
          >
            <div className="col-6">
              {!isShowItems ? (
                <CustomSkeleton shape="text" />
              ) : (
                <CommonListFilterItem item={item} type={"template"}/>
              )}
            </div>
            <div className="col-6">
              {!isShowItems ? (
                <CustomSkeleton shape="text" />
              ) : (
                <div>{item?.count}</div>
              )}
            </div>
          </div>
        );
      };

    return (
    <>
        {documentBillingByTemplate?.resultData?.length != 0 ? (
          <div className="grid-table filter-grid dash-filter-grid my-3 caseload-custom gridheight casload boxHeight traningList topFixHeader ">
            <h5>
              {" "}
              Template: ({totalValue})
            </h5>
            <ListView
              className="traningList"
              data={billingData}
              item={MyItemRender}
              style={{
                width: "100%",
                display: "block",
              }}
              header={MyHeader}
            />
          </div>
        ) : (
          <p>No Record found</p>
        )}
      </>
    );
};

export default DocumentByTemplate;





