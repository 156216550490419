import { API_ENDPOINTS } from "src/services/api-endpoints";
import { customAxios } from "src/services/useExPressApi";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { clientQuestionnairesSentAction } from "src/redux/actions";

const onFinish = () => {
  window.parent.postMessage(
    JSON.stringify({
      error: false,
      message: "FillableQue",
    }),
    "*"
  );
  window.parent.postMessage(
    JSON.stringify({
      error: false,
      message: "FillableQueNavigate",
    }),
    "*"
  );
};

const ClientQuestionnaireService = {

  updatequestionnaireFromPortal: async (selectedClientId, totalSum, listItems, questionId, token) => {
    try {
      const response = await customAxios.put(
        API_ENDPOINTS.UPDATE_CLIENT_QUESTIONNAIRE,
        {
          clientId: selectedClientId,
          score: totalSum,
          dataObject: listItems,
          id: questionId,
        }, 
        {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
      );
      if(response?.status){

        NotificationManager.success('Questionnaire updated successfully');
        setTimeout(() => {
          onFinish()
      }, 2000) 
      }
    //   return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  completeQuestionnaireFromPortal: async (
    isUpdateOnly,
    queId,
    selectedClientId,
    totalSum,
    listItems,
    data,
    token
  ) => {
    try {
      if (isUpdateOnly) {
        // Only update the questionnaire status
        const response = await customAxios.post(
          API_ENDPOINTS.COMPLETE_CLINET_QUESTIONNAIRE,
          { id: queId, isCompleted: data?.isCompleted, Inprogress: data?.inProgress, sendToClientPortal: data?.sentToClientPortal },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.status) {
          NotificationManager.success("Questionnaire status updated successfully");
          setTimeout(() => {
            onFinish();
          }, 2000);
          return response;
        }
      } else {
        // Perform both update and completion steps
        const response = await customAxios.put(
          API_ENDPOINTS.UPDATE_CLIENT_QUESTIONNAIRE,
          {
            clientId: selectedClientId,
            score: totalSum,
            dataObject: listItems,
            id: queId,
            isCompleted: data?.isCompleted,
            InProgress: data?.inProgress,
            sendToClientPortal: data?.sendToClientPortal
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.status) {
          NotificationManager.success("Questionnaire updated successfully");
        }

        const responseForComplete = await customAxios.post(
          API_ENDPOINTS.COMPLETE_CLINET_QUESTIONNAIRE,
          { id: queId, isCompleted: data?.isCompleted, Inprogress: data?.inProgress, sendToClientPortal: data?.sentToClientPortal },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (responseForComplete?.status) {
          NotificationManager.success("Questionnaire status updated Successfully");     
          setTimeout(() => {
            onFinish();
          }, 2000);
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
};

export default ClientQuestionnaireService;