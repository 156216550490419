import React, { ComponentState, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClientService } from "src/services/clientService";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { NichqHeader } from "./Nichq-heads";
import { Spsrsbns_Ps_QueSet } from "./Spsrsbns_Ps_QueSet";
import { Spsrsbns_Ns_QueSet } from "./Spsrsbns_Ns_QueSet";
import ClientQuestionnaireService from "./functionToSaveQuestionnaireFromPortal";

export const SPSRSBNS_Scale = ({
  SPSRSBNS_PS,
  SPSRSBNS_PS_ADDON,
  SPSRSBNS_NS,
  SPSRSBNS_NS_ADDON,
  response,
  interfered,
  isPrint,
  questionId,
  isFromCP,
  queId, token
}) => {
  const [spsrsbns_Ps, setSpsrsbns_Ps] = useState<any[]>([]);
  const [spsrsbns_Ps_Addon, setSpsrsbns_Ps_Addon] = useState<any[]>([]);

  const [spsrsbns_Ns, setSpsrsbns_Ns] = useState<any[]>([]);
  const [spsrsbns_Ns_Addon, setSpsrsbns_Ns_Addon] = useState<any[]>([]);

  const { state } = useLocation();
  const selectedClientId = useSelector(
    (state: ComponentState) => state.selectedClientId
  );
  const navigate = useNavigate();

  useEffect(() => {
    const Spsrsbns_Ps = SPSRSBNS_PS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const Spsrsbns_Ps_Addon = SPSRSBNS_PS_ADDON.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const Spsrsbns_Ns = SPSRSBNS_NS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const Spsrsbns_Ns_Addon = SPSRSBNS_NS_ADDON.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    setSpsrsbns_Ps(Spsrsbns_Ps);
    setSpsrsbns_Ps_Addon(Spsrsbns_Ps_Addon);

    setSpsrsbns_Ns(Spsrsbns_Ns);
    setSpsrsbns_Ns_Addon(Spsrsbns_Ns_Addon);
  }, [response]);

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId,
      0,
      [
        ...spsrsbns_Ps,
        ...spsrsbns_Ps_Addon,
        ...spsrsbns_Ns,
        ...spsrsbns_Ns_Addon,
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };

  const handleSaveFromPortal = async () => {
    ClientQuestionnaireService.updatequestionnaireFromPortal(selectedClientId, 0, 
      
      [
        ...spsrsbns_Ps,
        ...spsrsbns_Ps_Addon,
        ...spsrsbns_Ns,
        ...spsrsbns_Ns_Addon,
      ],queId, token)
  }

  const handleCompleteFromPortal = async () => {
    try {
      ClientQuestionnaireService.completeQuestionnaireFromPortal(false, queId, selectedClientId, 0,       [
        ...spsrsbns_Ps,
        ...spsrsbns_Ps_Addon,
        ...spsrsbns_Ns,
        ...spsrsbns_Ns_Addon,
      ],  {isCompleted: true, inProgress: false, sentToClientPortal: null}, token)
    } catch (error) {
      console.error('Error completing client questionnaire:', error);
      NotificationManager.error('Failed to complete client questionnaire');
    }
  };

  return (
    <div className="ml-3 f-15 f-15 spsrDocument">
      <div className="mt-4 head-6 f-15 f-14">
        4-ITEM POSITIVE SYMPTOM RATING SCALE (PSRS)
        <br />
        (Version 5.0)
      </div>
      <div className="head-6">SCALE ITEMS AND ANCHOR POINTS</div>

      <div className="mt-4 f-14 f-14">
        <div>
          <label>1. SUSPICIOUSNESS:  &nbsp;{" "}
         
            {" "}
            Expressed or apparent belief that other persons have acted
            maliciously or with discriminatory intent. Include persecution by
            supernatural or other nonhuman agencies (e.g., the devil). Note:
            Ratings of "3" or above should also be rated under Unusual Thought
            Content.{" "} </label>
         
        </div>
        <br />
        <br />
        <div className="ml-5">
          <ul>
            <li className=" f-14  text-black">
             
              <label className="mt-2 ">
              {" "}
                Do you ever feel uncomfortable in public? Does it seem as though
                others are watching you?{" "}
              </label>{" "}
            </li>
            <li className=" f-14  text-black">
              <label className="mt-2 ">
                {" "}
                Are you concerned about anyone's intentions toward you?{" "}
              </label>{" "}
            </li>
            <li className="f-14  text-black">
              <label className="mt-2">
                {" "}
                Is anyone going out of their way to give you a hard time, or
                trying to hurt you? Do you feel in any danger?{" "}
              </label>{" "}
            </li>
          </ul>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className="ml-4">
            <label>
              [If patient reports any persecutory ideas/delusions, ask the
              following]:
            </label>
          </div>
          <li className="f-14 margin-L">
            <label className="mt-2">
              How often have you been concerned that [use patient's
              description]? Have you told anyone about these experiences?{" "}
            </label>{" "}
            <br />
          </li>
        </div>
      </div>
      <div className="mt-2 px-5 f-15 f-15 f-14">
        <div className="row md-12 f-15">
          <div className="md-4 head-6 f-15 f-14">
            <label>1. Not Present</label>
          </div>
          <div className="md-8 f-15 ">&nbsp;</div>
        </div>
        <br />
        <div className="row md-12 f-15 ">
          <div className="md-4 f-15 ">
            <label>2. Very Mild</label>
          </div>

          <div className="md-8 f-15 wrap-content head-6">
            <label>
              &nbsp; Seems on guard. Reluctant to respond to some "personal"
              questions. Reports being overly selfconscious in public.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15 f-15">
          <div className="md-4 head-6">
            <label>3. Mild</label>
          </div>
          <div className="md-8 f-15 f-15 wrap-content">
            <label>
              &nbsp; Describes incidents in which others have harmed or wanted
              to harm him/her that sound plausible. Patient feels as if others
              are watching, laughing, or criticizing him/her in public, but this
              occurs only occasionally or rarely. Little or no preoccupation.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15 f-15">
          <div className="md-4 head-6">
            <label>4. Moderate</label>
          </div>
          <div className="md-8 f-15 f-15 wrap-content">
            <label>
              &nbsp; Says others are talking about him/her maliciously, have
              negative intentions, or may harm him/her. Beyond the likelihood of
              plausibility, but not delusional. Incidents of suspected
              persecution occur occasionally (less than once per week) with some
              preoccupation.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15 f-15">
          <div className="md-4 head-6 f-15 f-15">
            <label>5. Moderately Severe</label>
          </div>
          <div className="md-8 f-15 f-15 wrap-content">
            <label>
              &nbsp; Same as 4, but incidents occur frequently, such as more
              than once per week. Patient is moderately preoccupied with ideas
              of persecution OR patient reports persecutory delusions expressed
              with much doubt (e.g., partial delusion).
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15 f-15">
          <div className="md-4 f-15 f-15">
            <label>6. Severe</label>
          </div>
          <div className="md-8 f-15 f-15 wrap-content">
            <label>
              &nbsp; Delusional -- speaks of Mafia plots, the FBI, or others
              poisoning his/her food, persecution by supernatural forces.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15 f-15">
          <div className="md-4 f-15 f-15">
            <label>7. Extremely Severe</label>
          </div>
          <div className="md-8 f-15 f-15">
            <label>
              &nbsp; Same as 6, but the beliefs are bizarre or more
              preoccupying. Patient tends to disclose or act on persecutory
              delusions.
            </label>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <label className="f-14 ">2. UNUSUAL THOUGHT CONTENT: </label> &nbsp;
        <label>
          {" "}
          Unusual, odd, strange or bizarre thought content. Rate the degree of
          unusualness, not the degree of disorganization of speech. Delusions
          are patently absurd, clearly false or bizarre ideas that are expressed
          with full conviction. Consider the patient to have full conviction if
          he/she has acted as though the delusional belief were true. Ideas of
          reference/persecution can be differentiated from delusions in that
          ideas are expressed with much doubt and contain more elements of
          reality. Include thought insertion, withdrawal and broadcast. Include
          grandiose, somatic and persecutory delusions even if rated elsewhere.
          Note: If Suspiciousness is rated "6" or "7" due to delusions, then
          Unusual Thought Content must be rated a "4" or above.
        </label>
        <div className="ml-5">
          <li className="f-14 margin-L">
            <label>
              Have you been receiving any special messages from people or from
              the way things are arranged around you? Have you seen any
              references to yourself on TV or in the newspapers?{" "}
            </label>
          </li>{" "}
          <li className="f-14 margin-L">
            <label>Can anyone read your mind?</label>
          </li>
          <li className="f-14 margin-L">
            <label> Do you have a special relationship with God? </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              Is anything like electricity, X-rays, or radio waves affecting
              you?{" "}
            </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              Are thoughts put into your head that are not your own?{" "}
            </label>
          </li>{" "}
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              Have you felt that you were under the control of another person or
              force?{" "}
            </label>
          </li>{" "}
          <br />
          &nbsp;&nbsp;&nbsp;
          <div className="ml-4">
            <label>
              [If patient reports any odd ideas/delusions, ask the following]:{" "}
            </label>
          </div>{" "}
          <br />
          <li className="f-14 margin-L">
            {" "}
            <label>
              How often do you think about [use patient's description]?{" "}
            </label>
          </li>{" "}
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              Have you told anyone about these experiences? How do you explain
              the things that have been happening [specify]?{" "}
            </label>
          </li>
          <br />
        </div>
      </div>
      <div className="mt-2 px-5">
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>1. Not Present</label>
          </div>
          <div className="md-8 f-15">&nbsp;</div>
        </div>
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>2. Very Mild</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; Ideas of reference (people may stare or may laugh at him),
              ideas of persecution (people may mistreat him). Unusual beliefs in
              psychic powers, spirits, UFOs, or unrealistic beliefs in one's own
              abilities. Not strongly held. Some doubt.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>3. Mild</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; Same as 2, but degree of reality distortion is more severe
              as indicated by highly unusual ideas or greater conviction.
              Content may be typical of delusions (even bizarre), but without
              full conviction. The delusion does not seem to have fully formed,
              but is considered as one possible explanation for an unusual
              experience.{" "}
            </label>
          </div>
        </div>
        <br></br>
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>4. Moderate</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Delusion present but no preoccupation or functional
              impairment. May be an encapsulated delusion or a firmly endorsed
              absurd belief about past delusional circumstances.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>5. Moderately Severe</label>
          </div>
          <div className="md-8 f-15">
            <label>
              &nbsp; Full delusion(s) present with some preoccupation OR some
              areas of functioning disrupted by delusional thinking.
            </label>
          </div>
        </div>
        <br></br>
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>6. Severe</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Full delusion(s) present with much preoccupation OR many
              areas of functioning are disrupted by delusional thinking.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>7. Extremely Severe</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Full delusions present with almost total preoccupation OR
              most areas of functioning are disrupted by delusional thinking.
            </label>
          </div>
        </div>
      </div>
      <br />
      <div className="mt-4">
        <label>3. HALLUCINATIONS:  &nbsp; Reports of perceptual
        experiences in the absence of relevant external stimuli. When rating
        degree to which functioning is disrupted by hallucinations, include
        preoccupation with the content and experience of the hallucinations, as
        well as functioning disrupted by acting out on the hallucinatory content
        (e.g., engaging in deviant behavior due to command hallucinations).
        Include "thoughts aloud" ("gedankenlautwerden") or pseudohallucinations
        (e.g., hears a voice inside head) if a voice quality is present.
        </label>
        <br />
        <br />
        <div className="ml-5">
          <li className="f-14 margin-L">
            <label> Do you ever seem to hear your name being called? </label>
          </li>{" "}
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              Have you heard any sounds or people talking to you or about you
              when there has been nobody around?{" "}
            </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              [If hears voices]: What does the voice/voices say? Did it have a
              voice quality?{" "}
            </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label>
              Do you ever have visions or see things that others do not see'?
              What about smell — odors that others do not smell?{" "}
            </label>
          </li>
          <br />
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="ml-4">
          <label>   [If the patient reports hallucinations, ask the following]: </label>
          </div>{" "}
          <br />
          <li className="f-14 margin-L">
            {" "}
            <label>
              {" "}
              Have these experiences interfered with your ability to perform
              your usual activities/work?{" "}
            </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label> How do you explain them? How often do they occur? </label>
          </li>
        </div>
      </div>
      <div className="mt-2 px-5 f-15">
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>1. Not Present</label>
          </div>
          <div className="md-8 f-15 ">&nbsp;</div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>2. Very Mild</label>
          </div>
          <div className="md-8  f-15 wrap-content">
            <label>
              {" "}
              &nbsp; While resting or going to sleep, sees visions, smells
              odors, or hears voices, sounds or whispers in the absence of
              external stimulation, but no impairment in functioning.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>3. Mild</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; While in a clear state of consciousness, hears a voice
              calling the subject’s name, experiences non-verbal auditory
              hallucinations (e.g., sounds or whispers), formless visual
              hallucinations, or has sensory experiences in the presence of a
              modality-relevant stimulus (e.g., visual illusions) infrequently
              (e.g., 1-2 times per week) and with no functional impairment.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>4. Moderate</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; Occasional verbal, visual, gustatory, olfactory, or tactile
              hallucinations with no functional impairment OR non-verbal
              auditory hallucinations/visual illusions more than infrequently or
              with impairment.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>5. Moderately Severe</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Experiences daily hallucinations OR some areas of
              functioning are disrupted by hallucinations.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>6. Severe</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Experiences verbal or visual hallucinations several times a
              day OR many areas of functioning are disrupted by these
              hallucinations.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>7. Extremely Severe</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Persistent verbal or visual hallucinations throughout the
              day OR most areas of functioning are disrupted by these
              hallucinations.{" "}
            </label>
          </div>
        </div>
      </div>
      <br />
      <div className="mt-4">
        <label>4. CONCEPTUAL DISORGANIZATION:  &nbsp; Degree to which
        speech is confused, disconnected, vague or disorganized. Rate
        tangentiality, circumstantiality, sudden topic shifts, incoherence,
        derailment, blocking, neologisms, and other speech disorders. Do not
        rate content of speech.
        </label>
        <br />
      </div>
      <br />
      <div className="mt-2 px-5">
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>1. Not Present</label>
          </div>
          <div className="md-8 f-15">&nbsp;</div>
        </div>
        <br />
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>2. Very Mild</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Peculiar use of words or rambling but speech is
              comprehensible.{" "}
            </label>
          </div>
        </div>
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>3. Mild</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Speech a bit hard to understand or make sense of due to
              tangentiality, circumstantiality, or sudden topic shifts.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>4. Moderate</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Speech difficult to understand due to tangentiality,
              circumstantiality, idiosyncratic speech, or topic shifts on many
              occasions OR 1-2 instances of incoherent phrases.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>5. Moderately Severe</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Speech difficult to understand due to circumstantiality,
              tangentiality, neologisms, blocking, or topic shifts most of the
              time OR 3-5 instances of incoherent phrases.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>6. Severe</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Speech is incomprehensible due to severe impairments most
              of the time. Many PSRS items cannot be rated by self-report alone.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>7. Extremely Severe</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Speech is incomprehensible throughout interview.{" "}
            </label>
          </div>
        </div>
      </div>

      {spsrsbns_Ns_Addon.length > 0 && (
        <NichqHeader
          title={""}
          disabled={isFromCP ? false : (response && response.length>0)}
          subTitle={""}
          listItems={spsrsbns_Ns_Addon}
          setListItems={setSpsrsbns_Ns_Addon}
        />
      )}

      <hr></hr>
      <div className="mt-4 head-6  f-15">
        <label>
          {" "}
          BRIEF NEGATIVE SYMPTOM ASSESSMENT SCALE
          <br />
          (version 5.0)
        </label>
      </div>
      <div className="f-14">
       <label>Items adapted from NSA and SANS </label> </div>

      <div className="mt-4">
        <label>1. PROLONGED TIME TO RESPOND (a measure of Alogia) : </label>{" "}
        <label>
          {" "}
          &nbsp; Observed throughout communication with the patient. After
          asking the patient a question, he or she pauses for inappropriately
          long periods before initiating a response. Delay is considered a pause
          if it feels as though you are waiting for a response or if you
          consider repeating the question because it appears that the patient
          has not heard you. He or she may seem "distant," and sometimes the
          examiner may wonder if he has even heard the question. Prompting
          usually indicates that the patient is aware of the question, but has
          been having difficulty in developing his thoughts in order to make an
          appropriate reply. Rate severity on the frequency of these pauses.{" "}
        </label>
      </div>
      <div className="mt-2 px-5">
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>1. Normal:</label>
          </div>
          <div className="md-8 f-15">
            <label> &nbsp; No abnormal pauses before speaking. </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>2. Minimal:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Minimal evidence of inappropriate pauses (brief but not
              abnormally lengthy pauses occur) may be extreme of normal{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>3. Mild:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Occasional noticeable pauses before answering questions.
              Due to the length of the pause, you feel the need to repeat
              yourself once or twice during the interview.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>4. Moderate:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Distinct pauses occur frequently (20-40% of responses).{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>5. Marked:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Distinct pauses occur most of the time (40-80% of
              responses).{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>6. Severe:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Distinct pauses occur with almost every response (80-100%
              of responses).{" "}
            </label>
          </div>
        </div>
      </div>
      <br />
      <div className="mt-4">
        <label>
          2. EMOTION: UNCHANGING FACIAL EXPRESSION; BLANK, EXPRESSIONLESS FACE
          (a measure of Flat Affect):
        </label>{" "}
        <label>
          {" "}
          &nbsp; The patient’s face appears wooden, mechanical, frozen. Facial
          musculature is generally expressionless and unchanging. The patient
          does not change expression, or change is less than normally expected,
          as the emotional content of discourse changes. Because of this,
          emotions may be difficult to infer. Disregard changes in facial
          expression due to abnormal involuntary movements, such as tics and
          tardive dyskinesia. The two dimensions of importance when making this
          rating are degree of emotional expression and spontaneity.
        </label>
        <br />
      </div>
      <div className="mt-2 px-5">
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>1 Normal:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Spontaneous displays of emotion occur when expected. Normal
              degree of expressiveness of emotions is present.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>2 Minimal:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Spontaneous expressions of emotion occur when expected.
              However, there is a reduction in degree or intensity of the
              emotions expressed. May be extreme of normal.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>3 Mild:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Spontaneous expressions of emotion occur infrequently. When
              emotions are expressed, there is a reduction in degree or
              intensity displayed.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>4 Moderate:</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; Obvious reduction in spontaneous expressions. Spontaneous
              expressions of emotion may occur very rarely during interaction
              and only when discussing topics of special interest or humor to
              the subject.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>5 Marked:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Facial expression is markedly decreased. There are no
              spontaneous expressions of emotion unless prompted or coaxed by
              the interviewer.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>6 Severe:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; There are no expressions of emotion even when attempts are
              made to elicit an emotional response. The subject’s face remains
              blank throughout the interview.
            </label>
          </div>
        </div>
      </div>
      <br />

      <div className="mt-4">
        <label>3. REDUCED SOCIAL DRIVE. (a measure of Asociality): </label>{" "}
        &nbsp;
        <div className="f-14">
          <label>
            {" "}
            This item assesses how much the subject desires to initiate social
            interactions. Desire may be measured in part by the number of actual
            or attempted social contacts with others. If the patient has
            frequent contact with someone (e.g., family member) who initiates
            the contact, does the patient appear to desire the contact (i.e.,
            would he or she initiate contact if necessary?)? In making this
            rating, probe the desire to initiate social interactions, number of
            social interactions, and the ability to enjoy them.
          </label>
        </div>
        <br />
        <div className="ml-5">
          <li className="f-14 margin-L">
            <label> Assessed by asking the patient questions like: </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label> How have you spent your time in the past week? </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label> Do you live alone or with someone else? </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            {" "}
            <label> Do you like to be around people? </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label> Do you spend much time with others? </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label> Do you have difficulty feeling close to others? </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            {" "}
            <label> Who are your friends? </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            {" "}
            <label> How often do you see them?</label>{" "}
          </li>
          <br />
          <li className="f-14 margin-L">
            {" "}
            <label> Did you see them this past week? </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            {" "}
            <label> Have you called them on the phone? </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              When you get together, who decides what to do and where to go?{" "}
            </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              When you spend time with others, do you ask them to do something
              with you or do you wait until they ask you to do something?{" "}
            </label>
          </li>{" "}
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              Is anyone concerned about your happiness or well being?{" "}
            </label>
          </li>
        </div>
      </div>
      <div className="mt-2 px-5">
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>1 Normal:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Normal desire to initiate and normal number of contacts.
              Social contacts are enjoyable.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>2 Minimal:</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; Minimal reduction in either the desire to initiate social
              contacts or the number of social relationships. May initially seem
              guarded, but has the ability to establish relationships over time.
              Social relationships are enjoyable.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>3 Mild:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Reduction in desire to initiate social contacts. The
              patient has few social relationships and these social contacts are
              enjoyable.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>4 Moderate:</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; Obvious reduction in the desire to initiate social
              contacts. The patient has few relationships toward which he or she
              feels indifference. However, a number of social contacts are
              initiated each week.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>5 Marked:</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; Marked reduction in desire to initiate social contacts. The
              patient has very few relationships toward which he or she feels
              indifference. The patient does not initiate social contacts but
              may maintain a few contacts (such as with family).{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12">
          <div className="md-4 f-15">
            <label>6 Severe:</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Patient does not desire social contact. Actively avoids
              social interactions.{" "}
            </label>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <label>4. GROOMING AND HYGIENE (a measure of Amotivation): </label>{" "}
        &nbsp;
        <div className="f-14">
          <label>
            Observed during interaction with the patient. The patient displays
            less attention to grooming and hygiene than normal. The patient
            presents with poorly groomed hair, disheveled clothing, etc. Do not
            rate grooming as poor if it is simply done in what one might
            consider poor taste (e.g., wild hairdo or excessive makeup). In
            addition to observation, one must ask the patient about regularity
            of bathing, brushing teeth, changing clothes, etc. This is
            particularly important with outpatients, as the patient may present
            his or her best grooming and hygiene at their clinic visit. Two
            dimensions
          </label>
        </div>
        <br />
        <div className="ml-5">
          <li className="f-14 margin-L">
            <label> Assess the patient by asking questions like: </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label>
              {" "}
              How many times in the past week have you taken a shower or bath?{" "}
            </label>
          </li>
          <br />
          <li className="f-14 margin-L">
            <label> How often do you change your clothes? </label>
          </li>{" "}
          <br />
          <li className="f-14 margin-L">
            <label> How often do you shower and brush your teeth? </label>
          </li>
          <br />
        </div>
      </div>
      <br />
      <div className="mt-2 px-5">
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>1 Normal</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Patient is clean (e.g., showers every day) and dressed
              neatly.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>2 Minimal</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Minimal reduction in grooming and hygiene, may be at the
              extreme end of the normal range.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>3 Mild</label>
          </div>
          <div className="md-8 f-15">
            <label>
              {" "}
              &nbsp; Apparently clean but untidy appearance. Clothing may be
              mismatched. Patient may shower less often than every other day, or
              may brush teeth less than everyday.{" "}
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>4 Moderate</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; There is an obvious reduction in grooming and hygiene.
              Clothes may appear unkempt, rumpled, or the patient may look as if
              he or she just got out of bed. The patient may go without
              showering or bathing for two days at a time. The patient may go
              for two days without brushing his/her teeth.
            </label>
          </div>
        </div>
        <br />
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>5 Marked</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; There is a marked reduction in grooming and hygiene.
              Clothing may appear dirty, stained, or very unkempt. The subject
              may have greasy hair or a body odor. The patient may go 3 days at
              a time without showering or 3 or 4 days without brushing his/her
              teeth.
            </label>
          </div>
        </div>
        <div className="row md-12 f-15">
          <div className="md-4 f-15">
            <label>6 Severe</label>
          </div>
          <div className="md-8 f-15 wrap-content">
            <label>
              {" "}
              &nbsp; Clothing is badly soiled. Patient has a foul odor. Patient
              may go more than 4 days in a row without showering or more than 4
              days in a row without brushing his/her teeth. Poor hygiene may
              present a health risk.{" "}
            </label>
          </div>
        </div>
      </div>

      <div className="mt-4 head-6">
        SCORE SHEET for
        <br />
        4-ITEM POSITIVE SYMPTOM RATING SCALE AND
        <br />
        BRIEF NEGATIVE SYMPTOM ASSESSMENT
      </div>

      <div className="mt-4">
        <label>
          <u>4-Item Positive Symptom Rating Scale</u>
        </label>{" "}
        <br />
        <div className="f-14">
         <label>Use each item's anchor points to rate the patient. </label> 
        </div>
      </div>

      {spsrsbns_Ps.length > 0 && (
        <Spsrsbns_Ps_QueSet
          subTitle={""}
          listItems={spsrsbns_Ps}
          setListItems={setSpsrsbns_Ps}
        />
      )}
      <div>
        <label className=" head-6  f-15">* NA - not able to be assessed</label>{" "}
        <br />
      </div>

      <div className="mt-4">
        <label>
          <u>4-Item Positive Symptom Rating Scale</u>
        </label>{" "}
        <br />
        <div className="f-14">
         <label> Use each item's anchor points to rate the patient. </label>
        </div>
      </div>

      {spsrsbns_Ns.length > 0 && (
        <Spsrsbns_Ns_QueSet
          subTitle={""}
          listItems={spsrsbns_Ns}
          setListItems={setSpsrsbns_Ns}
        />
      )}
      {spsrsbns_Ps_Addon.length > 0 && (
        <NichqHeader
          title={""}
          disabled={isFromCP ? false : (response && response.length>0)}
          subTitle={""}
          listItems={spsrsbns_Ps_Addon}
          setListItems={setSpsrsbns_Ps_Addon}
        />
      )}

      <p className="f-12 mt-3">
        <label>
          The 4-item PSRS was adapted from the Expanded Version of the BPRS
          developed by:
        </label>
        <br />
       <label> Ventura, J.; Lukoff, D.; Nuechterlein, K.H.; Liberman, R.P.; Green,
        M.F.; and Shaner, A. Manual for the expanded Brief Psychiatric Rating
        Scale. International Journal of Methods Psychiatry Research, 3:227-244,
        1993
        </label>
      </p>
      <div className="f-12 mt-3 f-15">
        <label>
          The Brief Negative Symptom Assessment was adapted from the Negative
          Symptom Assessment and the Scale for the Assessment of Negative
          Symptoms developed respectively by:
        </label>
        <br />
        <label>
        Alphs and Summerfelt. The Negative Symptom Assessment: A new instrument
        to assess negative symptoms of schizophrenia. Psychopharmacology
        Bulletin, 1989. 25(2): p. 159-163.
        </label>
        <br />
        <label>
        Andreason, N. Modified scale for the assessment of negative symptoms.
        NIMH treatment strategies in schizophrenia study. Public Health
        Administration U.S. Department of Health and Human Services, 1984. ADM
        (9/85): p. 9-102.
        </label>
      </div>

      {isFromCP ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleCompleteFromPortal} className="submitButon">
            Save & Complete
          </button>
          <button onClick={handleSaveFromPortal} className="cancelBtn">
            Save progress
          </button>
          </div>
        ) : state == null && !isPrint ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleSubmit} className="submitButon">
              Submit
            </button>
          </div>
        ) : null}
    </div>
  );
};
