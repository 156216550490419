const APP_ROUTES = {

  //    ------------claim page-----
  CLAIM_PAGE: "/claimpage",


  // -------------Video Chat---------------

  TELEHEALTH: "/telehealth",

  LANDING_PAGE: "/landing",

  // -------------Provider---------------

  NEW_BILLING: "/billing/new",
  BILLING_TRANSACTION: "/billing/submissions",

  BILLING_REMITTANCE: "/billing/remittance",

  BILLING_PROFILE: "/billing/profile",
  BILLING_PROFILE_ADD: "/billing/profile/add",
  BILLING_PROFILE_EDIT: "/billing/profile/edit",


  // -------------Provider---------------

  ADD_CLINIC: "/provider/add",
  EDIT_CLINIC: "/provider/edit/",
  UPDATE_CLINIC: "/provider/edit/:id",
  GET_CLINIC: "/provider/list",
  TIMECLOCK: "/time-clock",
  KNOWLEDGE_BASE: "/knowledge-base",
  //ADD_CLINIC_SITE: "/provider/location/add",
  //EDIT_CLINIC_SITE: "/provider/location/edit/",
  //UPDATE_SITE: "/provider/location/edit/:id",
  //GET_CLINIC_SITE: "/provider/location/list",

  ADD_CLINIC_SITE: "/provider/site/add",
  EDIT_CLINIC_SITE: "/provider/site/edit/",
  UPDATE_SITE: "/provider/site/edit/:id",
  GET_CLINIC_SITE: "/provider/site/list",

  // -----------------Staff------------------
  ADD_STAFF: "/staff/add",
  GET_STAFF: "/staff/list",
  EDIT_STAFF: "/staff/edit",
  EDIT_STAFF_BY_ID: "/staff/edit/:id",
  STAFF_PROFILE: "/staff/profile",
  STAFF_SIGNATURE: "/staff/signature",
  STAFF_POSITION_CREDENTIALS: "/staff/position/credentials",
  STAFF_CASELOAD: "/staff/caseload",
  STAFF_SITES: "/staff/sites",
  STAFF_CERTIFICATE: "/staff/certificate",
  STAFF_STORED_DOCUMENTS: "/staff/stored",
  STAFF_ADD_STORED_DOCUMENT: "/staff/document/add",
  STAFF_EDIT_STORED_DOCUMENT: "/staff/document/edit",
  STAFF_SETTING: "/staff/setting",
  // STAFF_NOTIFICATION_SETTING: "/staff/notification-setting",

  TRACK_STAFF_TIME: "/track/staff",
  STAFF_TEAM: "/staff/staff-team",
  STAFF_PAY: "/staff/pay-rates",
  STAFF_GOAL: "/staff/goals",

  // -----------------Dashboard------------------
  DASHBOARD: "/dashboard",
  // -----------------Roles------------------

  //-----------------------Task manager-----------------
  TASK_MANAGER: "/task/manager",
  TASK_DISCUSSION: "/task/discussion",

  // -----------------Insurance------------------
  ADD_INSURANCE: "/insurance/add",
  GET_INSURANCE_LIST: "/insurance/list",
  EDIT_INSURANCE: "/insurance/edit/",
  UPDATE_INSURANCE: "/insurance/edit/:id",
  ADD_CLIENT_INSURANCE: "/client/insurance/add",

  // -----------------Client------------------
  ADD_CLIENT: "/client/add",
  GET_CLIENT: "/client/list",

  // CLIENT _BILLINGS
  BILLING_CLAIMS: "/client/billing-claims",
  // UPDATE_CLIENT: "/client/edit/:id",
  CLIENT_DASHBOARD: "/client/dashboard",
  CLIENT_HEADER: "/client/header",
  CLIENT_SIGNATURE: "/client/signature",

  UPDATE_CLIENT_VITAL: "/ClientVital/edit",
  DELETE_CLIENT_VITAL: "/ClientVital/delete",
  GET_CLIENT_BY_ID: "ClientVital/list",
  GET_CLIENT_VITAL: "/client/vital/list",
  ADD_CLIENT_VITAL: "/client/vital/add",

  DIAGNOSIS: "/client/diagnosis",
  CLIENT_DOCUMENTS: "/client/documents",

  INSURANCE: "/client/insurance",
  EDIT_CLIENT_INSURANCE: "/client/insurance/edit",

  ADD_TREATMENT_PLAN: "/client/treatment",
  STORED_DOCUMENTS: "/client/storedDocuments",
  ADD_STORED_DOCUMENTS: "/client/storedDocuments/add",
  EDIT_STRORED_DOCUMENT: "/client/storedDocumnet/edit",
  CLIENT_DISCHARGE: "/client/discharge",
  QUESTIONNAIRE: "/client/questionnaire",
  CREATE_QUESTIONNAIRE: "/client/questionnaire/add",
  PRINT_QUESTIONNAIRE: "/client/questionnaire/print",

  MEDICATION: "/client/medication",
  COMPLIANCE: "/client/compliance",
  CLIENT_EPRESCRIPTION: "/client/e-prescription",

  // -----------------Authorization------------------

  AUTHORIZATION_LIST: "/client/authorization/list",
  AUTHORIZATION_ADD: "/client/authorization/add",
  AUTHORIZATION_EDIT: "/client/authorization/edit",

  // ----------------- Add Notes------------------

  // ----------------- SCHEDULER ------------------
  SCHEDULER: "/scheduler",

  // ----------------- Message ------------------
  MESSAGE: "/messages/",
  MESSAGE_SENT: "/messages/sent",
  MESSAGE_TRASH: "/messages/trash",

  // -----------------Service Manager------------------

  ADD_Services: "/Services/add",
  GET_SERVICE_BY_CLINICID: "/Services/list",
  EDIT_Services: "/Services/edit",
  EDIT_Services_BY_ID: "/Services/edit",
  GET_Services_BY_ID: "/Services/detail",

  // ----------------- Document Templates ------------------
  DOCUMENT_TEMPLATE_LIST: "/documents/template/list",
  DOCUMENT_TEMPLATE_VIEW: "/documents/template/view",
  DOCUMENT_TEMPLATE_ADD: "/documents/template/add",
  DOCUMENT_TEMPLATE_STAFF: "/staff/template-assign",

  // ----------------- Document Templates Draft ------------------
  DOCUMENT_TEMPLATE_DRAFT_LIST: "/documents/template/draft/list",

  // ----------------- Documents ------------------
  DOCUMENT_DRAFT_LIST: "/documents/draft/list",
  DOCUMENT_LIST: "/document/list",
  DOCUMENT_HISTORY: "/document/history",
  VIEW_DOCUMENT_EDIT_HISTORY: "/document/history/view",

  DOCUMENT_VIEW: "/document/view",
  DOCUMENT_PRINT: "/document/print",

  DOCUMENT_VIEW_PARAM: "document/view/:id",

  DOCUMENT_MULTI_VIEW: "/document/multi-view",
  DOCUMENT_ADD: "/document/add",
  DOCUMENT_EDIT: "/document/edit",

  // ----------------------------BACKGROUND----------------------------
  CLIENT_BACKGROUND: "/client/background",

  //E-prescription
  CLIENT_E_PRESCRIPTION: "/client/e-prescription",

  // -----------------------Eligibility-----------------
  CLIENT_ELIGIBILITY: "/client/eligibility",


  // -----------------------Immunization-----------------
  CLIENT_IMMUNIZATION: "/client/immunization",
  ADD_IMMUNIZATION: "/client/immunization/add",
  EDIT_IMMUNIZATION: "/client/immunization/edit",
  DETAIL_IMMUNIZATION: "/client/immunization/details",
  // -----------------------Assign Staff-----------------
  ASSIGN_STAFF_TO_CLIENT: "/client/assign-staff",


  // --------------------------Clinic Settings-------------------

  REF_PROVIDER_SETTINGS: "/settings/provider",
  REF_SOURCE_SETTINGS: "/settings/source",
  CLINIC_INFO: "/settings/clinic-info",
  DOCUMENT_SETTINGS: "/settings/document",
  CERTIFICATION_SETTINGS: "/settings/certification",
  CLINIC_FLAGS: "/settings/clinic-flags",
  CLINIC_TAGS: "/settings/clinic-tags",
  CLIENT_STATUS: "/settings/clinicStatus",
  ADD_MULTIPLE_CLIENT_AUTH: "/multipleClientAuth/add",
  EDIT_MULTIPLE_CLIENT_AUTH: "/multipleClientAuth/edit",
  CLINIC_PAYERS: "/settings/clinic-payers",
  IMPORT_CLINIC: "/settings/import-clients",
  CLIENT_COMPLIANCE: "/client-compliance",
  COMPLIANCE_REPORT: "/compliance-report",
  ROLES: "/roles",
  STAFF_DOCUMENTS: "/staff-documents",
  CREATE_STAFF_DOCUMENTS: "/staff-documents/add-new",
  PRESCRIPTIONS: "/settings/prescriptions",
  ADD_PRESCRIPTIONS: "/settings/prescriptions/new",
  CLINIC_SKILLS: "/settings/clinic-skills",
  CALENDAR_SETTINGS: "/settings/calendar-settings",

  // ------------------------ Client Guardian ------------------
  CLIENT_GUARDIAN_LIST: "/client/contacts-relations/list",
  CLIENT_GUARDIAN_ADD: "/client/guardian/add",
  CLIENT_COLLATERALCONTACT_ADD: "/client/collateral-contact/add",

  // ------------------------ Client Physician ------------------
  CLIENT_PHYSICIAN: "/client/physician",
  CLIENT_PHYSICIAN_LIST: "/client/physician/list",
  CLIENT_PHYSICIAN_ADD: "/client/physician/add",

  // ----------------------Contact Notes-----------------------

  CLIENT_CONTACT_NOTES: "/client/contact/list",

  // -----------------------Assign Services-----------------
  ASSIGN_SERVICE_TO_CLIENT: "/client/assign-service",
  ASSIGN_SERVICE_TO_STAFF: "/staff/assign-service",

  // ---------------------Signature------------------
  ADD_PARENT_SIGNATURE: "/client/signature/addParent",
  ADD_CLIENT_SIGNATURE: "/client/signature/addClient",

  // -----------------------Audit----------------------
  STAFF_AUDIT: "/staffaudit",
  CLIENT_AUDIT: "/clientaudit",
  CLINIC_AUDIT: "/audit/clinicAudit",
  INTERNAL_SERVER_ERROR: "/internal-server-error",

  // --------------------------Service Atuth-----------------------
  MULTIPLE_CLIENT_SERVICE_AUTH: "/authorizations",

  // -------------------------Default Goals------------------------

  DEFAULT_GOLAS: "/defaultgoals",
  ADD_NEW_DEFAULT_GOALS: "/newdefaultgoals",
  VIEW_GOAL_PAGE: "/viewgoal",
  VIEW_TREATMENT_PLAN_TEMPLATES: "/viewtreatmentplantemplates",

  // -----------------------------------------Demo-----------------------

  // MUI_FORM: "/form",

  // -----------------------------------------Access denied-----------------------
  // ACCESS_DENIED: "/accessDenied",


  DASHBOARD_CHART: "/dashboardchart",

  // ------------------------------------More ----------------------------------

  SERVICE_SETS: "/servicesets",
  CALL_BY_CLIENT: "/impersonate",
  UPDATE_QUESTIONNAIRE_FROM_PORTAL: "/impersonate/questionnaire",
  AUTHORIZATION_SETS: "/authorization-sets",
  CLINIC_FILE: "/clinic-file",
  ADD_CLINIC_FILE: "/Clinic/file-add",
  CLINIC_EDIT_STORED_DOCUMENT: "/clinic/document/edit",
  CLINIC_NOTIFICATION: "/settings/notification",
  LAST_DOC_VIEW: "/latest-document",
  TREATMENT_PLAN_SETTING: "/settings/treatmentPlan",
  GENERATE_TIMECLOCK_REPORT: "/time-clock-report",
  VIEW_STAFF_DOCUMENTS: "/staff-documents/view",
  STAFF_DOCUMENT_TEMPLATE: "/staff-document-templates/list",
  ADMIN_VIEW_DOCUMENT_FILE_TEMPLATE: "/document/template/view/",
  SERVICE_RULES: "/service/service-rules",
  SUPER_BILL: "/client/documents/super-bill",
  STAFF_DOCUMENT_PRINT: "/staff-documents-print",

  // ------------ client ledger routes -----------------

  CLIENT_LEDGER: "/client/ledger/list",
  ADD_CLIENT_LEDGER: "/client/ledger/add",
  EDIT_CLIENT_LEDGER: "/client/ledger/edit",
  APPOINTMENT_REMINDERS: "/settings/text-reminders",
  CLIENT_PORTAL_ACCESS: "/client-portal-account-access",
  ADD_CLIENT_PORTAL_ACCESS: "/client-portal-account-access/add",

  VIEW_SOCIAL_DETERMINANTS_HISTORY: "/client/background/social_determinants_history",
  MIN_SERVICE_REPORT: "/client/minimum-service-report",
  ASSIGN_STAFF_REPORT: "/staff/assign-staff-report",
  REPORT_MANAGER: "/report-manager",
  SUBSCRIPTION_MANAGER: "/subscription-manager",
  MAP: "/client/caseload",
  PAY_SUBSCRIPTION: "/payments",
  STAFF_SERVICE_REPORT:"/staff/staff-service-report"
};

export default APP_ROUTES;
