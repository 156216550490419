import React, { ComponentState, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClientService } from "src/services/clientService";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";


import { Phq9Mft } from "./Phq9Mft";
import { NichqHeader } from "./Nichq-heads"; 
import ClientQuestionnaireService from "./functionToSaveQuestionnaireFromPortal";






export const PHQ_9_TEEN = ({ PHQ9MFT, PHQ9MFTAddON, PHQ9MFT_HEADERS, response, interfered, isPrint, questionId, isFromCP, queId, token }) => {

  const [phqTeensSet, setPhqTeensSet] = useState<any[]>([]);
  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const navigate = useNavigate();

  const [phqHeader, setPhqHeader] = useState<any[]>([]);
  const [phqAddOnQuestion, setPhqAddOnQuestion] = useState<any[]>([]);


  useEffect(() => {

    const listPhq = PHQ9MFT.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const h2 = PHQ9MFT_HEADERS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const h3 = PHQ9MFTAddON.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    setPhqTeensSet(listPhq)
    setPhqHeader(h2)
    setPhqAddOnQuestion(h3)


  }, [response])

  const handleSaveFromPortal = async () => {
    ClientQuestionnaireService.updatequestionnaireFromPortal(selectedClientId, 0, 
      
      [
        ...phqTeensSet,
        ...phqHeader,
        ...phqAddOnQuestion,
      ],
       queId, token)
  }

  const handleCompleteFromPortal = async () => {
    try {
      ClientQuestionnaireService.completeQuestionnaireFromPortal(false, queId, selectedClientId, 0,       
        [
          ...phqTeensSet,
          ...phqHeader,
          ...phqAddOnQuestion,
        ],  {isCompleted: true, inProgress: false, sentToClientPortal: null}, token)
    } catch (error) {
      console.error('Error completing client questionnaire:', error);
      NotificationManager.error('Failed to complete client questionnaire');
    }
  };

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [
        ...phqTeensSet,
        ...phqHeader,
        ...phqAddOnQuestion,
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };

  return (
    <div  className="ml-3">
      <div className="mt-4">
        <h4>PHQ-9: Modified for Teens</h4>
      </div>
      {
        phqHeader.length > 0 &&
        <div className="row mt-4">
          <NichqHeader

            title={""}
            disabled={isFromCP ? false : (response && response.length>0)}
            subTitle={''}
            listItems={phqHeader}
            setListItems={setPhqHeader}
          />
        </div>

      }
      <label>
        <b>Instructions:</b> How often have you been bothered by each of the following symptoms during the past <u>two weeks</u>? For each symptom put an "&#10006;" in the box beneath the answer that best describes how you have been feeling.
      </label>
      {
        phqTeensSet.length > 0 &&
        <Phq9Mft

          subTitle={''}
          listItems={phqTeensSet}
          setListItems={setPhqTeensSet}
        />
      }


      {
        phqAddOnQuestion.length > 0 &&
        <NichqHeader

          title={""}
          disabled={isFromCP ? false : (response && response.length>0)}
          subTitle={''}
          listItems={phqAddOnQuestion}
          setListItems={setPhqAddOnQuestion}
        />
      }


{isFromCP ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleCompleteFromPortal} className="submitButon">
            Save & Complete
          </button>
          <button onClick={handleSaveFromPortal} className="cancelBtn">
            Save progress
          </button>
          </div>
        ) : state == null && !isPrint ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleSubmit} className="submitButon">
              Submit
            </button>
          </div>
        ) : null}

      <label className="f-12 mt-3">
        **If you have had thoughts that you would be better off dead or of hurting yourself in some way,
        please discuss this with your Health Care Clinician, go to a hospital emergency room or call 911.

      </label>

      <label>
        Modified with permission by the GLAD-PC team from the PHQ-9 (Spitzer, Williams, & Kroenke, 1999), Revised PHQ-A (Johnson,
        2002), and the CDS (DISC Development Group, 2000)
      </label>
      <label>
        <h5>Scoring the PHQ-9 modified for Teens</h5>
        
        Scoring the PHQ-9 modified for teens is easy but involves thinking about several different aspects of depression.
        <br />
        To use the PHQ-9 as a diagnostic aid for Major Depressive Disorder:
        <ul>
          <li>Questions 1 and/or 2 need to be endorsed as a "2" or "3"</li>
          <li>Need five or more positive symptoms (positive is defined by a "2" or "3" in questions 1-8 and by a "1", "2", or "3" in question 9).</li>
          <li>The functional impairment question (How difficult….) needs to be rated at least as "somewhat difficult."</li>
        </ul>
        <br />
        To use the PHQ-9 to screen for all types of depression or other mental illness:
        <ul>
          <li>All positive answers (positive is defined by a "2" or "3" in questions 1-8 and by a "1", "2", or "3" in question 9) should be  followed up by interview.</li>
          <li>A total PHQ-9 score &#62; 10 (see below for instructions on how to obtain a total score) has a good sensitivity and specificity for MDD.</li>
        </ul>
        <br />
        To use the PHQ-9 to aid in the diagnosis of dysthymia:
        <br />
        <ul>
          <li>The dysthymia question (In the past year…) should be endorsed as "yes."</li> 
        </ul>
        <br />

        To use the PHQ-9 to screen for suicide risk:
        <ul>
          <li>All positive answers to question 9 as well as the two additional suicide items MUST be followed up by a clinical interview. </li>
        </ul>
        <br />

        To use the PHQ-9 to obtain a total score and assess depressive severity:
        <ul>
          <li>Add up the numbers endorsed for questions 1-9 and obtain a total score.</li>
          <li>See Table below:</li>
        </ul>
      </label>
      <div>

        <div className="row  client-name-bdss">
          <div className="col-md-4"><label className="f-14">Total Score</label></div>
          <div className="col-md-8"><label className="f-14">Depression Severity</label></div>
        </div>

        <div className="row  client-name-bdss">
          <div className="col-md-4"><label>0-4 </label></div>
          <div className="col-md-8"><label>No or Minimal depression </label> </div>
        </div>
        <div className="row  client-name-bdss">
          <div className="col-md-4"><label>5-9 </label></div>
          <div className="col-md-8"><label>Mild depression </label></div>
        </div>
        <div className="row  client-name-bdss">
          <div className="col-md-4"><label>10-14  </label></div>
          <div className="col-md-8"><label>Moderate depression </label></div>
        </div>
        <div className="row  client-name-bdss">
          <div className="col-md-4"><label>15-19 </label></div>
          <div className="col-md-8"><label>Moderately severe depression</label> </div>
        </div>
        <div className="row  client-name-bdss">
          <div className="col-md-4"><label>20-27</label> </div>
          <div className="col-md-8"><label>Severe depression </label></div>
        </div>
      </div>






    </div>

  )

} 