import React, { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceCodeChartModel } from "src/dataModels/docListModel";
import { userIsSuperAdmin, userIsAdmin } from "src/helper/permission-helper";
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import CustomSkeleton from "src/control-components/skeleton/skeleton";
import CommonListFilterItem from "./common-list-filter-item";

const DocumentsByServiceCode = () => {
  const state: ComponentState = useSelector((states) => {
    return states;
  });
  const staffLoginInfo = useSelector(
    (state: ComponentState) => state.getStaffReducer
  );
  const isBillingManager = state.getRolePermission.isBillingManager;
  const [serviceCodeData, setServiceCodeData] = useState<
    serviceCodeChartModel[]
  >([]);
  const { documentByServiceCode } = state["DocumentReducer"];
  const userIsSA = userIsSuperAdmin(staffLoginInfo?.roleId);
  const showBillingAmounts = state.getRolePermission.showBillingAmounts;
  const canShowBillData = isBillingManager || userIsSA || showBillingAmounts;
  const [isShowItems, setShowItems] = useState(false);

  useEffect(() => {
    setServiceCodeData([]);
    if (documentByServiceCode && documentByServiceCode?.resultData) {
      setShowItems(true);
      // Sort the data by 'totDocs' in descending order
      const sortedData = documentByServiceCode?.resultData
        ?.slice()
        .sort((a, b) => b.totDocs - a.totDocs);
      sortedData?.map((item) => {
        const totalbillValue = Number(item?.totBilled).toFixed(2);
        const formattedTotalBill = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(totalbillValue));
        var obj: serviceCodeChartModel = {
          id: item?.serviceId,
          serviceCode: item?.serviceCode,
          documents: item?.totDocs,
          billingAmount: canShowBillData ? formattedTotalBill : "",
        };
        setServiceCodeData((oldItem) => [...oldItem, obj]);
      });
    }
  }, [documentByServiceCode]);

  const MyHeader = () => {
    return (
      <ListViewHeader
        style={{
          color: "#000000",
          fontSize: 30,
        }}
        className="px-3 py-2 "
      >
        <div className="row border-bottom align-middle">
          <div className="col-4">
            <h2 className="f-14">Service Code</h2>
          </div>
          <div className="col-4">
            <h2 className="f-14"># of Docs</h2>
          </div>
          <div className="col-4">
            <h2 className="f-14 ">Billing Charges</h2>
          </div>
        </div>
      </ListViewHeader>
    );
  };
  const MyItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div
        className="row py-2 border-bottom align-middle"
        style={{ margin: 0 }}
      >
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <CommonListFilterItem item={item} type={"service"}/>
          )}
        </div>
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.documents}</div>
          )}
        </div>
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.billingAmount}</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {documentByServiceCode?.resultData?.length != 0 ? (
        <div className="grid-table filter-grid dash-filter-grid my-3 caseload-custom gridheight casload boxHeight traningList topFixHeader">
          <h5> Documents by Service Code</h5>
          <ListView
            className="traningList"
            data={serviceCodeData}
            item={MyItemRender}
            style={{
              width: "100%",
              display: "block",
            }}
            header={MyHeader}
          />
        </div>
      ) : (
        <p>No Record found</p>
      )}
    </>
  );
};

export default DocumentsByServiceCode;
