import { useDispatch } from "react-redux";
import { ComponentState } from "react";
import { saveDocumentSearchFilter } from "src/redux/actions";
import { useSelector } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";

enum FilterType {
  STAFF = "staff",
  CLIENT = "client",
  TEMPLATE = "template",
  PAYER = "payer",
  BILL_STATUS = "billStatus",
  DOCUMENT_STATUS = "documentStatus",
  SERVICE = "service",
}

const CommonListFilterItem = ({ item, type }) => {
  const state: ComponentState = useSelector((states) => {
    return states;
  });
  const docFilter = state.getDocumentFilter;
  const dispatch = useDispatch();

  const handleClick = () => {
    const updatedFilterObject = { ...docFilter };
    const formattedItem = {
      id: item?.id,
      name:
        item?.label || item?.serviceCode || item?.clientName || item?.staffName,
    };

    switch (type) {
      case FilterType.STAFF:
        updatedFilterObject.staff = [formattedItem];
        break;
      case FilterType.CLIENT:
        updatedFilterObject.client = [formattedItem];
        break;
      case FilterType.TEMPLATE:
        updatedFilterObject.template = [formattedItem];
        break;
      case FilterType.PAYER:
        updatedFilterObject.payer = [formattedItem];
        break;
      case FilterType.BILL_STATUS:
        updatedFilterObject.billStatus = [formattedItem];
        break;
      case FilterType.DOCUMENT_STATUS:
        updatedFilterObject.documentStatus = formattedItem;
        break;
      case FilterType.SERVICE:
        updatedFilterObject.service = [formattedItem];
        break;
      default:
        break;
    }
    dispatch(saveDocumentSearchFilter(updatedFilterObject));
  };

  return (
    <Tooltip
      anchorElement="target"
      position="right"
      className="tooltop_Custom"
    >
      <div
        className="cursor-pointer text-theme" 
        title="Apply filter"
        onClick={handleClick}
      >
        {item?.staffName ||
          item?.clientName ||
          item?.label ||
          item?.serviceCode ||
          "N/A"}
      </div>
    </Tooltip>
  );
};

export default CommonListFilterItem;
