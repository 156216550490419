import React, { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ClientService } from "../../../../../../services/clientService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import APP_ROUTES from "src/helper/app-routes";
import { useLocation } from "react-router";
import { renderErrors } from "src/helper/error-message-helper";
import { NichqComponent1 } from "./Nichq-set1";
import { NichqComponent2 } from "./Nichq-set2";
import { NichqComponent3 } from "./Nichq-set3";
import { NichqHeader } from "./Nichq-heads";
import { NichqComments } from "./Nichq-comments";
import TextAreaKendo from "src/control-components/kendo-text-area/kendo-text-area";
import ClientQuestionnaireService from "./functionToSaveQuestionnaireFromPortal";


const NICHQAssessment = ({
  questionId,
  head1,
  head2,
  head3,
  head4,
  comment1, comment2, comment3, comment4,
  list_p_assessment,
  list_p_performance,
  list_t_assessment,
  list_t_performance,
  list_t_performance_beh,
  list_parent_followup,
  list_parent_followup_performance,
  list_parent_side_effect,
  list_teacher_followup,
  list_teacher_followup_performance,
  list_teacher_side_effect,

  response,
  interfered,
  isPrint,
  isFromCP, queId, token
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);

  const [parentAssList, setParentAssList] = useState<any[]>([]);
  const [parentAssPerList, setParentAssPerList] = useState<any[]>([]);
  const [teacherAssList, setTeacherAssList] = useState<any[]>([]);
  const [teacherAssPerList, setTeacherAssPerList] = useState<any[]>([]);
  const [parentFollowUpList, setParentFollowUpList] = useState<any[]>([]);
  const [parentFollowUpPerList, setParentFollowUpPerList] = useState<any[]>([]);
  const [parentFollowUpSideEffectList, setParentFollowUpSideEffectList] = useState<any[]>([]);
  const [teacherFollowUpList, setTeacherFollowUpList] = useState<any[]>([]);
  const [teacherFollowUpPerList, setTeacherFollowUpPerList] = useState<any[]>([]);
  const [teacherFollowUpSideEffectList, setTeacherFollowUpSideEffectList] = useState<any[]>([]);
  const [head1Set, setHead1Set] = useState<any[]>([]);
  const [head2Set, setHead2Set] = useState<any[]>([]);
  const [head3Set, setHead3Set] = useState<any[]>([]);
  const [head4Set, setHead4Set] = useState<any[]>([]);
  const [commentSet1, setCommentSet1] = useState<any[]>([]);
  const [commentSet2, setCommentSet2] = useState<any[]>([]);
  const [commentSet3, setCommentSet3] = useState<any[]>([]);
  const [commentSet4, setCommentSet4] = useState<any[]>([]);
  const [teacherAssBehPerList, setTeacherAssBehPerList] = useState<any[]>([]);

  useEffect(() => {


    if (response) {

      const behPr = list_t_performance_beh.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });

      const h1 = head1.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });
      const h2 = head2.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });
      const h3 = head3.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });

      const h4 = head4.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });

      const l11 = list_parent_followup.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });
      const l10 = list_parent_followup_performance.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });
      const l9 = list_parent_side_effect.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });
      const l8 = list_teacher_followup.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });
      const l7 = list_teacher_followup_performance.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });

      const l4 = list_t_performance.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });




      const l2 = list_p_performance.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });

      const l3 = list_t_assessment.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });

      const l6 = list_teacher_side_effect.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });

      const l1 = list_p_assessment.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });


      const c1 = comment1.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });

      const c2 = comment2.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });
      const c3 = comment3.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });
      const c4 = comment4.map((item) => {
        const foundItem = response.find((i) => i.questionId === item.questionId);
        if (foundItem) {
          if (item.type === "question") {
            if (foundItem.value) {
              return { ...item, isSelected: true, value: foundItem.value };
            } else {
              return { ...item, isSelected: false, value: "" };
            }
          }
          return { ...item, ...foundItem };
        } else {
          return item;
        }
      });

      setParentAssList(l1);
      setParentAssPerList(l2)
      setTeacherAssList(l3)
      setTeacherAssPerList(l4);
      setTeacherFollowUpSideEffectList(l6)
      setTeacherFollowUpPerList(l7)
      setTeacherFollowUpList(l8)
      setParentFollowUpSideEffectList(l9)
      setParentFollowUpPerList(l10)
      setParentFollowUpList(l11)
      setHead1Set(h1)
      setHead2Set(h2)
      setHead3Set(h3)
      setHead4Set(h4)
      setCommentSet1(c1)
      setCommentSet2(c2)
      setCommentSet3(c3)
      setCommentSet4(c4)
      setTeacherAssBehPerList(behPr)

    }
  }, [response]);

  
  const handleSaveFromPortal = async () => {
    ClientQuestionnaireService.updatequestionnaireFromPortal(selectedClientId, 0, 
      
       [...parentAssList,
        ...parentAssPerList,
        ...teacherAssList,
        ...teacherAssPerList,
        ...parentFollowUpList,
        ...parentFollowUpPerList,
        ...parentFollowUpSideEffectList,
        ...teacherFollowUpList,
        ...teacherFollowUpPerList,
        ...teacherFollowUpSideEffectList,
        ...head1Set,
        ...head2Set,
        ...head3Set,
        ...head4Set,
        ...commentSet1,
        ...commentSet2,
        ...commentSet3,
        ...commentSet4,
        ...teacherAssBehPerList
        ],
       queId, token)
  }

  const handleCompleteFromPortal = async () => {
    try {
      ClientQuestionnaireService.completeQuestionnaireFromPortal(false, queId, selectedClientId, 0,        [...parentAssList,
        ...parentAssPerList,
        ...teacherAssList,
        ...teacherAssPerList,
        ...parentFollowUpList,
        ...parentFollowUpPerList,
        ...parentFollowUpSideEffectList,
        ...teacherFollowUpList,
        ...teacherFollowUpPerList,
        ...teacherFollowUpSideEffectList,
        ...head1Set,
        ...head2Set,
        ...head3Set,
        ...head4Set,
        ...commentSet1,
        ...commentSet2,
        ...commentSet3,
        ...commentSet4,
        ...teacherAssBehPerList
        ],  {isCompleted: true, inProgress: false, sentToClientPortal: null}, token)
    } catch (error) {
      console.error('Error completing client questionnaire:', error);
      NotificationManager.error('Failed to complete client questionnaire');
    }
  };

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [...parentAssList,
      ...parentAssPerList,
      ...teacherAssList,
      ...teacherAssPerList,
      ...parentFollowUpList,
      ...parentFollowUpPerList,
      ...parentFollowUpSideEffectList,
      ...teacherFollowUpList,
      ...teacherFollowUpPerList,
      ...teacherFollowUpSideEffectList,
      ...head1Set,
      ...head2Set,
      ...head3Set,
      ...head4Set,
      ...commentSet1,
      ...commentSet2,
      ...commentSet3,
      ...commentSet4,
      ...teacherAssBehPerList
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };


  return (
    <div className="child_main_table mt-3 col-12">
      <div>
        <div className="head-5">NICHQ Vanderbilt Assessment Scale—PARENT Informant</div>
        <div className="head-6">Used for diagnosing ADHD </div>
      </div>


      {(head1Set && head1Set.length > 0) &&
        <div className="row mt-4">
          <NichqHeader
            title={''}
            disabled={isFromCP ? false : (response && response.length>0)}
            subTitle={'Directions: Each rating should be considered in the context of what is appropriate for the age of your child. When completing this form, please think about your child’s behaviors in the past 6 months.'}
            listItems={head1Set}
            setListItems={setHead1Set}
          /></div>}

      {(parentAssList && parentAssList.length > 0) &&
        <NichqComponent1

          subTitle={'Symptoms'}
          listItems={parentAssList}
          setListItems={setParentAssList}
        />}



      {parentAssPerList.length > 0 &&
        <NichqComponent2

          subTitle={'Performance'}
          listItems={parentAssPerList}
          setListItems={setParentAssPerList}
        />
      }

      {commentSet1.length > 0 &&
        <>
          <NichqComments
            title={'Comments'}

            readOnly={response && response.length > 0}
            listItems={commentSet1}
            setListItems={setCommentSet1}
          />
          <div className="row mb-3 pb-2 border-bottom" style={{ height: 4, backgroundColor: 'black' }}></div></>
      }



      <div>
        <div className="head-4">NICHQ Vanderbilt Assessment Teacher Informant</div>

      </div>
      {(head2Set && head2Set.length > 0) &&
        <div className="row mt-4">
          <NichqHeader
            title={''}
            subTitle={'Directions: Each rating should be considered in the context of what is appropriate for the age of the child you are rating and should reflect that child’s behavior since the beginning of the school year. Please indicate the number of weeks or months you have been able to evaluate the behaviors:'}
            disabled={isFromCP ? false : (response && response.length>0)}
            listItems={head2Set}
            setListItems={setHead2Set}
          /></div>}
      {teacherAssList.length > 0 &&
        <NichqComponent1

          subTitle={'Comments'}
          listItems={teacherAssList}
          setListItems={setTeacherAssList}
        />
      }



      {teacherAssPerList.length > 0 &&
        <NichqComponent2

          subTitle={'Academic Performance'}
          listItems={teacherAssPerList}
          setListItems={setTeacherAssPerList}
        />
      }
       {teacherAssBehPerList.length > 0 &&
        <NichqComponent2

          subTitle={'Classroom Behavioral Performance'}
          listItems={teacherAssBehPerList}
          setListItems={setTeacherAssBehPerList}
        />
      }

      {commentSet2.length > 0 &&
        <>
          <NichqComments
            title={'Comments'}

            readOnly={response && response.length > 0}
            listItems={commentSet2}
            setListItems={setCommentSet2}
          /> <div className="row mb-3 pb-2 border-bottom" style={{ height: 4, backgroundColor: 'black' }}></div>
        </>
      }



      <div>
        <div className="head-4">NICHQ Vanderbilt Assessment Follow-up—PARENT Informant</div>

      </div>

      {(head3Set && head3Set.length > 0) &&
        <div className="row mt-4">
          <NichqHeader
            title={''}
            disabled={isFromCP ? false : (response && response.length>0)}
            subTitle={'Directions: Each rating should be considered in the context of what is appropriate for the age of your child. Please think about your child’s behaviors since the last assessment scale was filled out when rating his/her behaviors.'}
            listItems={head3Set}
            setListItems={setHead3Set}
          /></div>}
      {parentFollowUpList.length > 0 &&
        <NichqComponent1

          subTitle={'Symptoms'}
          listItems={parentFollowUpList}
          setListItems={setParentFollowUpList}
        />
      }

      {parentFollowUpPerList.length > 0 &&
        <NichqComponent2

          subTitle={'Performance'}
          listItems={parentFollowUpPerList}
          setListItems={setParentFollowUpPerList}
        />
      }


      {(parentFollowUpSideEffectList && parentFollowUpSideEffectList.length > 0) && <NichqComponent3
        title={'Side Effects'}
        subTitle={'Has your child experienced any of the following side Are these side effects currently a problem effects or problems in the past week?'}
        listItems={parentFollowUpSideEffectList}
        setListItems={setParentFollowUpSideEffectList}
      />}

      {commentSet3.length > 0 &&
        <>
          <NichqComments
            title={'Comments'}

            readOnly={response && response.length > 0}
            listItems={commentSet3}
            setListItems={setCommentSet3}
          />
          <div className="row mb-3 pb-2 border-bottom" style={{ height: 4, backgroundColor: 'black' }}></div>
        </>
      }


      <div>
        <div className="head-4">NICHQ Vanderbilt Assessment Follow-up—TEACHER Informant</div>
      </div>

      {(head4Set && head4Set.length > 0) &&
        <div className="row mt-4">
          <NichqHeader
            title={''}
            subTitle={'Directions: Each rating should be considered in the context of what is appropriate for the age of the child you are rating and should reflect that child’s behavior since the last assessment scale was filled out. Please indicate the number of weeks or months you have been able to evaluate the behaviors:'}
            disabled={isFromCP ? false : (response && response.length>0)}
            listItems={head4Set}
            setListItems={setHead4Set}
          /></div>}

      {teacherFollowUpList.length > 0 && <NichqComponent1
        subTitle={'Symptoms'}

        listItems={teacherFollowUpList}
        setListItems={setTeacherFollowUpList}
      />
      }

      {teacherFollowUpPerList.length > 0 && <NichqComponent2

        subTitle={'Performance'}
        listItems={teacherFollowUpPerList}
        setListItems={setTeacherFollowUpPerList}
      />
      }



      {(teacherFollowUpSideEffectList && teacherFollowUpSideEffectList.length > 0) && <NichqComponent3
        title={'Side Effects'}
        subTitle={'Has your child experienced any of the following side Are these side effects currently a problem effects or problems in the past week?'}
        listItems={teacherFollowUpSideEffectList}
        setListItems={setTeacherFollowUpSideEffectList}
      />}

      {commentSet4.length > 0 &&
        <NichqComments
          title={'Comments'}

          listItems={commentSet4}
          readOnly={response && response.length > 0}
          setListItems={setCommentSet4}
        />
      }

{isFromCP ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleCompleteFromPortal} className="submitButon">
            Save & Complete
          </button>
          <button onClick={handleSaveFromPortal} className="cancelBtn">
            Save progress
          </button>
          </div>
        ) : state == null && !isPrint ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleSubmit} className="submitButon">
              Submit
            </button>
          </div>
        ) : null}
    </div>
  );
};

export default NICHQAssessment;
