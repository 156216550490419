/**
 * App.js Layout Start Here
 */

import { AutoComplete } from "@progress/kendo-react-dropdowns";
import {
    SELECTED_CLIENT_ID,
    IS_GLOBAL_SEARCH,
    GET_CLIENT_DETAILS, GET_CLIENT_PROFILE_IMG, GET_CLIENT_INSURANCE, GET_CLIENT_FLAGS, GET_CLIENT_PRIMARY_DX,
    SELECTED_STAFF_ID,
    GET_STAFF_PROFILE_IMG,
    GET_STAFF_DETAILS
} from "../../redux/actions/types";
import ApiHelper from "../../helper/api-helper";

import { useDispatch} from "react-redux";
import { ClientService } from "../../services/clientService";
import { renderErrors } from "src/helper/error-message-helper";
import APP_ROUTES from "src/helper/app-routes";
import { useNavigate } from "react-router";
import { Encrption } from "src/app-modules/encrption";
import { API_ENDPOINTS } from "src/services/api-endpoints";

function GlobalSearch({ data, textField, onChange, label, name,
    dataItemKey, validityStyles, required, value, itemRender, filterable, onFilterChange, placeholder, isSelectedDDL, isGlobalSearchReducer,
    setSearchValue, setIsSelectedDDL,primaryPath,newPath,routeClient, isStaff, selectedStaffIdDropdown
}) {
    const dispatch = useDispatch();
    const getStaffDetail = (selectedStaffId) => {
        let id = Encrption(selectedStaffId);
        ApiHelper.getRequest(API_ENDPOINTS.GET_STAFF_BY_ID + id)
          .then((result) => {
            dispatch({
              type: GET_STAFF_DETAILS,
              payload: result.resultData,
            });
            let staffDetail = result.resultData;
            // setStaffInfo(staffDetail);
            // getCertificateExpiration(staffDetail?.id);
          })
          .catch((error) => {
            renderErrors(error.message);
          });
      };
    //   const getCertificateExpiration = async (selecetdID) => {
    //     let id = Encrption(selecetdID);
    //     ApiHelper.getRequest(API_ENDPOINTS.GET_STAFF_EXPIRING_CERTIFICATES + id)
    //       .then((result) => {
    //         const data = result.resultData;
    //         setExpiringCertificate(data);
    //       })
    //       .catch(() => {
    
    //       });
    //   };
    
    
    //   const getSignature = () => {
    //     ApiHelper.getRequest(
    //       API_ENDPOINTS.GET_STAFF_SIGNATURE + Encrption(selectedStaffId)
    //     )
    //       .then((result) => {
    //         if (result.resultData.length > 0) {
    //           let signList = result.resultData[0];
    //         //   setSignData("data:image/png;base64," + signList.signature);
    //         }
    //       })
    //       .catch((error) => {
    //         renderErrors(error.message);
    //       });
    //   };
    
      const getStaffProfileImg = async (selectedStaffId) => {
        let id = Encrption(selectedStaffId);
        await ApiHelper.getRequest(API_ENDPOINTS.GET_STAFF_PROFILE + id)
          .then((result) => {
    
            if (result.resultData !== null) {
            //   setProfilePic(result.resultData.staffProfileImageUrl);
              dispatch({
                type: GET_STAFF_PROFILE_IMG,
                payload: result.resultData,
              });
            }
          })
          .catch((error) => {
            renderErrors(error.message);
          });
      };
    
    //   const getStaffBillingProfile = async () => {
    //     let staffId = Encrption(selectedStaffId);
    //     ApiHelper.getRequest(API_ENDPOINTS.GET_STAFF_BILLING_PROFILE + staffId)
    //       .then((result) => {
    
    //         const data = result.resultData;
    //         setBillingProfileData(data);
    //       })
    //       .catch(() => {
    //       });
    //   };
  const navigate = useNavigate();
    const getClientDetail = async (element) => {
        await ClientService.getClientDetail(element)
            .then((result) => {
                dispatch({
                    type: GET_CLIENT_DETAILS,
                    payload: result.resultData,
                });
                getClientProfileImg(element);

            })
            .catch((error) => {
                renderErrors(error.message);
            });
    };

    const getCurrentInsurance = async (element) => {
        await ClientService.getClientCurrentInsurance(element)
            .then((result) => {
                let insuranceList = result.resultData;
                dispatch({
                    type: GET_CLIENT_INSURANCE,
                    payload: insuranceList,
                });
            })
            .catch((error) => {
                renderErrors(error.message);
            });
    };

    const getClientProfileImg = async (element) => {
        await ClientService.getClientProfileImg(element)
            .then((result) => {
                dispatch({
                    type: GET_CLIENT_PROFILE_IMG,
                    payload: result.resultData,
                });
            })
            .catch((error) => {
                renderErrors(error.message);
            });
    };
    
    const getClientDiagnose = async (sibId) => {
        await ClientService.getClientDiagnose(sibId )
          .then((result) => {
            let list = result.resultData;
            dispatch({
              type: GET_CLIENT_PRIMARY_DX,
              payload: result.resultData,
            });
          })
          .catch((error) => {
            renderErrors(error.message);
          });
      };
    
    const getClientTags = async (element) => {
        dispatch({
            type: GET_CLIENT_FLAGS,
            payload: [],
          });
        await ClientService.getClientFlags(element)
            .then((result) => {
                let list = result.resultData;
                dispatch({
                  type: GET_CLIENT_FLAGS,
                  payload: list,
                });
            })
            .catch((error) => {
                renderErrors(error.message);
            });
    };

    let element;
    if (data.length === 1) {
        for (var i = 0; i < data.length; i++) {
            isStaff ? element = data[i].id : element = data[i].clientId;
        }
        if (isSelectedDDL && !isStaff) {
           
            getClientDetail(element)
            getCurrentInsurance(element)
            getClientTags(element)
            getClientDiagnose(element)
            dispatch({
                type: SELECTED_CLIENT_ID,
                payload: element,
            });
            dispatch({
                type: IS_GLOBAL_SEARCH,
                payload: true,
            });
            if (primaryPath === "client") {
                if (routeClient) {
                  // navigate(APP_ROUTES.CLIENT_DASHBOARD);
                } else {
                  navigate(APP_ROUTES.CLIENT_DASHBOARD);
                }
              } else {
                navigate(APP_ROUTES.CLIENT_DASHBOARD);
              }
              if (newPath === APP_ROUTES.GET_CLIENT) {
                navigate(APP_ROUTES.CLIENT_DASHBOARD);
              }
            setIsSelectedDDL(false)


        }
        else if(isSelectedDDL && isStaff) {
            dispatch({
                type: SELECTED_STAFF_ID,
                payload: element,
            });
            dispatch({
                type: IS_GLOBAL_SEARCH,
                payload: true,
            });
            getStaffDetail(element);
            getStaffProfileImg(element);
            // getCertificateExpiration(element);
            // getSignature(element);
            if (primaryPath == "staff") {
                if (routeClient) {
                  // navigate(APP_ROUTES.CLIENT_DASHBOARD);
                } else {
                  navigate(APP_ROUTES.STAFF_PROFILE);
                }
              } else {
                navigate(APP_ROUTES.STAFF_PROFILE);
              }
              if (newPath === APP_ROUTES.STAFF_PROFILE) {
                navigate(APP_ROUTES.STAFF_PROFILE);
              }
            setIsSelectedDDL(false)
        }
    }
    else if (isStaff && selectedStaffIdDropdown && isSelectedDDL) {
      const filteredItem = data.find(item => item.id == selectedStaffIdDropdown);
    
      if (filteredItem) {
        dispatch({
          type: SELECTED_STAFF_ID,
          payload: filteredItem.id,
        });
        dispatch({
          type: IS_GLOBAL_SEARCH,
          payload: true,
        });
    
        getStaffDetail(filteredItem.id);
        getStaffProfileImg(filteredItem.id);
        // getCertificateExpiration(filteredItem); 
        // getSignature(filteredItem); 
    
        if (primaryPath === "staff") {
          if (routeClient) {
                              // navigate(APP_ROUTES.CLIENT_DASHBOARD);
          } else {
            navigate(APP_ROUTES.STAFF_PROFILE);
          }
        } else {
          navigate(APP_ROUTES.STAFF_PROFILE);
        }
    
        if (newPath === APP_ROUTES.STAFF_PROFILE) {
          navigate(APP_ROUTES.STAFF_PROFILE);
        }
      }
      setIsSelectedDDL(false);
    }
    

    return (
        <AutoComplete
            className='search_input_bar'
            label={label}
            onChange={onChange}
            data={data}
            textField={textField}
            value={value}
            name={name}
            validityStyles={validityStyles}
            required={required}
            dataItemKey={dataItemKey}
            itemRender={itemRender}
            filterable={filterable}
            onFilterChange={onFilterChange}
            dataId={element}
            placeholder={placeholder}
           
        />
    );
}
export default GlobalSearch;



