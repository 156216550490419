import { ReviewGoalResult, StaffTypesResult } from "src/dataModels/clinicResponseModels";
import {
  CLIENT_ID,
  CLIENT_INSURANCE_ID,
  SELECTED_CLIENT_ID,
  SELECTED_CLIENT_FILTER,
  REMOVE_CLIENT_FILTER,
  SELECTED_DRAWER_ROUTE,
  GET_CLIENT_DETAILS,
  GET_CLIENT_PROFILE_IMG,
  GET_CLIENT_INSURANCE,
  GET_CLIENT_PRIVATE_PAY_STATUS,
  GET_PRIMARY_CARE_PHYSICIAN,
  GET_PEDIATRICIAN,
  ALL_CLIENT_AVAILABLE,
  GET_CLIENT_FLAGS,
  SET_CLIENT_LIST_PAGE_NUMBER,
  JOIN_URL,
  SELECTED_CLIENT_NAME,
  SAVE_CLINIC_LOGO_BYTES,
  SAVE_CLINIC_LOGO_URL,
  SAVE_CLIENT_BILLING_CLAIMS,
  SAVE_CLIENT_COMPLIANCE_LIST,
  SAVE_CLINIC_FLAG,
  SAVE_CLIENT_STATUS,
  SAVE_UNREAD_COUNT,
  SAVE_MULTIPLE_CLIENTS,
  UNREAD_MESSAGE_COUNT,
  SAVE_ASSIGN_DEFAULT_GOALS_TO_CLIENT,
  SAVE_TP_STAFF_TYPE_DLL,
  SAVE_REVIEW_GOAL_BY_GOAL_ID,
  SAVE_ASSIGN_FLAG_TO_CLIENT,
  GET_CLIENT_PRIMARY_DX,
  SAVE_DUPLICATE_CLIENTS_DATA,
  CLIENT_LEDGER_FILTER_OBJ,
  SAVE_CLIENT_LEDGER_BY_CLIENT_ID,
  SAVE_CLIENT_LEDGER_PAYMENT_TYPES,
  SAVE_CLIENT_LEDGER_BY_ID,
  CLIENT_LIST_CHANGED,
  SAVE_CURRENT_LEDGER_BALANCE,
  CLIENT_LEDGER_BALANCE_CHANGED,
  SAVE_CLIENT_AUDIT_LOG_LIST,
  SAVE_CLIENT_AUDIT_LOG_META_DATA,
  CLIENT_AUDIT_LOG_LIST_CHANGED,
  CLIENT_AUDIT_FILTER,
  SAVE_COMPLIANCE_ATTACHED_DOC_LIST,
  SAVE_CP_ACC_ACCESS_LIST,
  LOADING_IN_CLIENT,
  SAVE_CP_ACCESS_CREATE_RESP,
  SAVE_CLIENT_MEDICATION_BY_CLIENT_ID,
  SAVE_CLIENT_MEDICATION_BY_ID,
  SAVE_SELECTED_ITEM_IN_CALENDER,
  SAVE_SOCIAL_DETERMINANTS_RESPONSE,
  SAVE_CONTACT_TYPE_LIST,
  SAVE_CLIENT_COLLATERAL_CONTACT,
  SAVE_CLIENT_COLLATERAL_CONTACT_BY_ID,
  LOADER_CLIENTS,
  SAVE_CLIENT_DOCUMENT_ATTACHMENTS,
  SAVE_COMPLIANCE_AUDIT_HISTORY,
  SAVE_POS_LIST,
  SAVE_REFERRAL_SOURCE_DELETE_RESP,
  SAVE_REFERRAL_PROVIDER_DELETE_RESP,
  SAVE_SERVICE_MIN_REPORT_BY_CLIENT_ID,
  CLIENT_STATUS_LOADING_STATE,
  SAVE_CLIENTS_FOR_CASELOAD_MAP,
  SAVE_UPDATE_AUTH_BATCH_END_DATE,
  REFRESH_TP,
  SAVE_TREATMENT_CP_FILE,
  SAVE_FINAL_RESPONSE_TO_SAVE_TP_FILE,
  SAVE_TP_PRINTED_FILES,
  SAVE_GENDER_IDENTITY,
  SAVE_MARITAL_STATUS,
  SAVE_SEXUAL_ORIENTATION,
  SAVE_CLIENT_MEDICAL_HISTORY,
  SAVE_FAMILY_MEDICAL_HISTORY,
  SAVE_CLIENT_MAJOR_MEDICAL_ISSUE,
  SAVE_CLIENT_QUESTIONNAIRE_BY_ID,
  CLIENT_QUESTIONNAIRES_SENT,
  SAVE_SERVICE_RULE_UTILIZATION_REPORT,


} from "../actions/types";
import { DuplicateDataResponse, genderIdentityDropdown, maritalStatusDropdown, sexualOrientationDropdown, ClientMedicalHistoryDataModal,FamilyMedicalHistoryDataModal,ClientMajorMedicalIssueResponseModal} from "src/dataModels/clientResponse";
import { GenericResponse } from "src/dataModels/authSetModels";



const initialState = {
  clinicLogoUrl: null,
  clinicLogoBytes: null,
  claimList: null,
  clientList: null,
  assignGoalResponse: 0,
  staffTypeList: [] as StaffTypesResult[],
  ReviewGoalList: [] as ReviewGoalResult[],
  duplicateClientResponseData: [] as DuplicateDataResponse[],
  clientLedgerFilterState: {},
  clientLedgerListByClientId: [],
  clientLedgerPaymentTypes: [],
  clientLedgerById: [],
  clientListChanged: true,
  currentLedgerBalance: 0,
  balanceChanged: false,
  clientAuditLogList: [],
  clientAuditLogMetaData: {},
  clientAuditLogListChanged: true,
  clientAuditFilters: {},
  complianceAttachedDocs: [],
  cpAccountAccessList: [],
  loadingStartedInCp: false,
  createResp: 0,
  clientMedicationByClientIdResp: {},
  clientMedicationByIdResp: {},
  selectedCalenderIdToOpen: {},
  socialDeterminantsResponse: {},
  contactTypeListResp: [],
  collateralContactResp: [],
  collateralContactByIdResp: {},
  clientLoading: false,
  clientDocAttachments: {},
  complianceAuditHistoryResp: [],
  PlaceOfServiceDDL: [],
  referralSourceDeleteResp: false,
  referralProviderDeleteResp: false,
  minServiceReportList: [],
  clientsForCaseloadMap: [],
  updateAuthBatchEndDateResp: [],
  refreshTP: false,
  clientFileForTp: {} as GenericResponse,
  clientFileForTpFinalResponse: {} as GenericResponse,
  treatmentPlanFiles:{} as GenericResponse,
  maritalStatusResp: {} as maritalStatusDropdown,
  genderIdentityResp: {} as genderIdentityDropdown,
  sexualOrientationResp: {} as sexualOrientationDropdown ,
  clientMedicalHistoryResp:{} as ClientMedicalHistoryDataModal,
  familyMedicalHistoryResp:{} as FamilyMedicalHistoryDataModal,
  clientMajorMedicalIssueResp:{} as ClientMajorMedicalIssueResponseModal,
  clientQuestionnaireByIdResp: [],
  clientQuestionnairesSent: false,
  serviveRuleUtilizationReport: [],
};

export const clientIDReducer = (state = false, action) => {
  switch (action.type) {
    case CLIENT_ID:
      return action.payload;
    default:
      return state;
  }
};

export const clientFlagsReducer = (state = false, action) => {
  switch (action.type) {
    case GET_CLIENT_FLAGS:
      return action.payload;
    default:
      return state;
  }
};

export const clientInsuranceIDReducer = (state = false, action) => {
  switch (action.type) {
    case CLIENT_INSURANCE_ID:
      return action.payload;
    default:
      return state;
  }
};

export const selectedClientIdReducer = (state = false, action) => {
  switch (action.type) {
    case SELECTED_CLIENT_ID:
      return action.payload;
    default:
      return state;
  }
};

export const selectedClientName = (state = false, action) => {
  switch (action.type) {
    case SELECTED_CLIENT_NAME:
      return action.payload;
    default:
      return state;
  }
};

export const selectedDrawer = (state = false, action) => {
  switch (action.type) {
    case SELECTED_DRAWER_ROUTE:
      return action.payload;
    default:
      return state;
  }
};

export const clientFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_CLIENT_FILTER:
      return {
        ...state,
        filter: action.payload,
      };

    case REMOVE_CLIENT_FILTER:
      return {
        ...state,
        filter: null,
      };
    default:
      return state;
  }
};

export const clientDetails = (state = false, action) => {
  switch (action.type) {
    case GET_CLIENT_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const getClientProfileImg = (state = false, action) => {
  switch (action.type) {
    case GET_CLIENT_PROFILE_IMG:
      return action.payload;
    default:
      return state;
  }
};


export const GetClinicLogoUrlReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const oldState = { ...state };
  switch (type) {
    case SAVE_CLINIC_LOGO_URL:
      return {
        ...oldState,
        clinicLogoUrl: payload,
      };
  }
  return oldState;
};

export const GetClinicLogoByteReducer = (state = initialState, action) => {
  const oldState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case SAVE_CLINIC_LOGO_BYTES:
      return payload
    default:
      return state;
  }
};

export const currentInsuranceDetails = (state = false, action) => {
  switch (action.type) {
    case GET_CLIENT_INSURANCE:
      return action.payload;
    default:
      return state;
  }
};
export const PrimaryDX = (state = false, action) => {
  switch (action.type) {
    case GET_CLIENT_PRIMARY_DX:
      return action.payload;
    default:
      return state;
  }
};
export const clientPrivatePay = (state = false, action) => {
  switch (action.type) {
    case GET_CLIENT_PRIVATE_PAY_STATUS:
      return action.payload;
    default:
      return state;
  }
};
export const getPrimaryCarePhysician = (state = false, action) => {
  switch (action.type) {
    case GET_PRIMARY_CARE_PHYSICIAN:
      return action.payload;
    default:
      return state;
  }
};
export const getPediatrician = (state = false, action) => {
  switch (action.type) {
    case GET_PEDIATRICIAN:
      return action.payload;
    default:
      return state;
  }
};

export const getAllclientAvailable = (state = false, action) => {
  switch (action.type) {
    case ALL_CLIENT_AVAILABLE:
      return action.payload;
    default:
      return state;
  }
};

export const getClientListPageNumber = (state = false, action) => {
  switch (action.type) {
    case SET_CLIENT_LIST_PAGE_NUMBER:
      return action.payload;
    default:
      return state;
  }
};

export const getJoinUrlLink = (state = false, action) => {
  switch (action.type) {
    case JOIN_URL:
      return action.payload;
    default:
      return state;
  }
}

export const ClientReducer = (state = initialState, action: any) => {
  const oldState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case SAVE_CLIENT_BILLING_CLAIMS:
      return {
        ...oldState,
        claimList: payload,
      };
    case SAVE_CLIENT_COMPLIANCE_LIST:
      return {
        ...oldState,
        clientComplianceList: payload,
      };
    case SAVE_CLINIC_FLAG:
      return {
        ...oldState,
        clinicFlagList: payload
      }
    case SAVE_CLIENT_STATUS:
      return {
        ...oldState,
        clientStatus: payload
      }
    case SAVE_UNREAD_COUNT:
      return {
        ...oldState,
        unreadCount: payload
      }


    case SAVE_MULTIPLE_CLIENTS:
      return {
        ...oldState,
        clientList: payload
      }
    case SAVE_ASSIGN_DEFAULT_GOALS_TO_CLIENT:
      return {
        ...oldState,
        assignGoalResponse: payload
      }


    case UNREAD_MESSAGE_COUNT:
      return {
        ...oldState,
        unreadMessageCount: payload
      }
    case SAVE_TP_STAFF_TYPE_DLL:
      return {
        ...oldState,
        staffTypeList: payload
      }
    case SAVE_REVIEW_GOAL_BY_GOAL_ID:
      return {
        ...oldState,
        ReviewGoalList: payload
      }
    case SAVE_ASSIGN_FLAG_TO_CLIENT:
      return {
        ...oldState,
        storeAssignFlagToClient: payload
      }

    case SAVE_DUPLICATE_CLIENTS_DATA:
      return {
        ...oldState,
        duplicateClientResponseData: payload
      }

    case SAVE_CLIENT_LEDGER_BY_CLIENT_ID:
      return {
        ...oldState,
        clientLedgerListByClientId: payload
      }

    case SAVE_CLIENT_LEDGER_PAYMENT_TYPES:
      return {
        ...oldState,
        clientLedgerPaymentTypes: payload
      }

      case CLIENT_QUESTIONNAIRES_SENT:
        return {
          ...oldState,
          clientQuestionnairesSent: payload
        }
    case SAVE_CLIENT_LEDGER_BY_ID:
      return {
        ...oldState,
        clientLedgerById: payload
      }

    case CLIENT_LEDGER_FILTER_OBJ:
      return {
        ...oldState,
        clientLedgerFilterState: payload
      }

    case CLIENT_LIST_CHANGED:
      return {
        ...oldState,
        clientListChanged: payload
      }

    case CLIENT_LEDGER_BALANCE_CHANGED:
      return {
        ...oldState,
        balanceChanged: payload
      }

    case SAVE_CURRENT_LEDGER_BALANCE:
      return {
        ...oldState,
        currentLedgerBalance: payload
      }

    case SAVE_CLIENT_AUDIT_LOG_LIST:
      return {
        ...oldState,
        clientAuditLogList: payload,
      };
    case SAVE_CLIENT_AUDIT_LOG_META_DATA:
      return {
        ...oldState,
        clientAuditLogMetaData: payload,
      };

    case CLIENT_AUDIT_LOG_LIST_CHANGED:
      return {
        ...oldState,
        clientAuditLogListChanged: payload,
      };

    case CLIENT_AUDIT_FILTER:
      return {
        ...oldState,
        clientAuditFilters: payload
      }

    case SAVE_COMPLIANCE_ATTACHED_DOC_LIST:
      return {
        ...oldState,
        complianceAttachedDocs: payload
      }

    case SAVE_CP_ACC_ACCESS_LIST:
      return {
        ...oldState,
        cpAccountAccessList: payload
      }

    case LOADING_IN_CLIENT:
      return {
        ...oldState,
        loadingStartedInCp: payload
      }

    case SAVE_CP_ACCESS_CREATE_RESP:

      return {
        ...oldState,
        createResp: payload
      }

    case SAVE_CLIENT_MEDICATION_BY_CLIENT_ID:

      return {
        ...oldState,
        clientMedicationByClientIdResp: payload
      }

    case SAVE_CLIENT_MEDICATION_BY_ID:

      return {
        ...oldState,
        clientMedicationByIdResp: payload
      }

    case SAVE_SELECTED_ITEM_IN_CALENDER:

      return {
        ...oldState,
        selectedCalenderIdToOpen: payload
      }

    case SAVE_SOCIAL_DETERMINANTS_RESPONSE:

      return {
        ...oldState,
        socialDeterminantsResponse: payload
      }

    case SAVE_CONTACT_TYPE_LIST:
      return {
        ...oldState,
        contactTypeListResp: payload
      }
    case SAVE_CLIENT_COLLATERAL_CONTACT:
      return {
        ...oldState,
        collateralContactResp: payload
      }
    case SAVE_CLIENT_COLLATERAL_CONTACT_BY_ID:
      return {
        ...oldState,
        collateralContactByIdResp: payload
      }
    case LOADER_CLIENTS:
      return {
        ...oldState,
        clientLoading: payload
      }
    case SAVE_CLIENT_DOCUMENT_ATTACHMENTS:
      return {
        ...oldState,
        clientDocAttachments: payload
      }
    case SAVE_COMPLIANCE_AUDIT_HISTORY:
      return {
        ...oldState,
        complianceAuditHistoryResp: payload
      }
    case SAVE_POS_LIST:
      return {
        ...oldState,
        PlaceOfServiceDDL: payload
      }
    case SAVE_REFERRAL_SOURCE_DELETE_RESP:
      return {
        ...oldState,
        referralSourceDeleteResp: payload
      }

    case SAVE_REFERRAL_PROVIDER_DELETE_RESP:
      return {
        ...oldState,
        referralProviderDeleteResp: payload
      }

    case SAVE_SERVICE_MIN_REPORT_BY_CLIENT_ID:
      return {
        ...oldState,
        minServiceReportList: payload
      }
    case CLIENT_STATUS_LOADING_STATE:
      return {
        ...oldState,
        clientStatusLoadingState: payload
      }
    case SAVE_CLIENTS_FOR_CASELOAD_MAP:
      return {
        ...oldState,
        clientsForCaseloadMap: payload
      }

    case SAVE_UPDATE_AUTH_BATCH_END_DATE:
      return {
        ...oldState,
        updateAuthBatchEndDateResp: payload
      }
    case REFRESH_TP:
      return {
        ...oldState,
        refreshTp: payload
      }
    case SAVE_TREATMENT_CP_FILE:
      return {
        ...oldState,
        clientFileForTp: payload
      }
    case SAVE_FINAL_RESPONSE_TO_SAVE_TP_FILE:
      return {
        ...oldState,
        clientFileForTpFinalResponse: payload
      }


    case SAVE_TP_PRINTED_FILES:
      return {
        treatmentPlanFiles: payload
      }

    case SAVE_GENDER_IDENTITY: 
      return {
        ...oldState,
        genderIdentityResp: payload
      }
    case SAVE_MARITAL_STATUS: 
      return {
        ...oldState,
        maritalStatusResp: payload
      }   
    case SAVE_SEXUAL_ORIENTATION: 
      return {
        ...oldState,
        sexualOrientationResp: payload
      }
      case SAVE_CLIENT_MEDICAL_HISTORY: 
      return {
        ...oldState,
        clientMedicalHistoryResp: payload
      }
    case SAVE_FAMILY_MEDICAL_HISTORY: 
      return {
        ...oldState,
        familyMedicalHistoryResp: payload
      }
    case SAVE_CLIENT_MAJOR_MEDICAL_ISSUE: 
      return {
        ...oldState,
        clientMajorMedicalIssueResp: payload
      }

    case SAVE_CLIENT_QUESTIONNAIRE_BY_ID: 
      return {
        ...oldState,
        clientQuestionnaireByIdResp: payload
      }
    case SAVE_SERVICE_RULE_UTILIZATION_REPORT: 
      return {
        ...oldState,
        serviveRuleUtilizationReport: payload
      }

  }


  return oldState;
};

// export const removeFilterReducer = (state = false, action) => {
//     switch (action.type) {
//         case REMOVE_CLIENT_FILTER:
//             return {
//                 ...state,
//                 filter: null
//             };

//         // return action.payload;
//         default:
//             return state;
//     }
// };
