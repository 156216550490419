import { filterBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ComponentState, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentServiceList, getDocumentbyId, updateDocumentService } from 'src/redux/actions';

const ServiceModel = ({ onClose, clientId, documentId, selectedServiceName, documentFormattedVal }) => {
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const { documentServiceList, documentsDetail } = state['DocumentReducer']


    const dispatch = useDispatch()
    const [isAccepted, setIsAccepted] = useState<boolean>(false)
    const [documentServiceData, setDocumentServiceData] = useState([])
    const [filter, setFilter] = useState<any>({});
    const [selectedService, setSelectedService] = useState<any>({ id: documentFormattedVal?.serviceId, name: documentFormattedVal?.serviceName })

    const [showServiceWarn, setShowServiceWarn] = useState(false);

    useEffect(() => {
        if (!isAccepted) {
            dispatch(getDocumentbyId(documentId))
            dispatch(getDocumentServiceList(clientId))
            setIsAccepted(true)
        }
    }, [isAccepted])

    useEffect(() => {
        if (documentServiceList && documentsDetail) {
            const list = documentServiceList?.map((x: any) => {
                return { id: x.serviceId, name: x.name };
            });
            setDocumentServiceData(list)

            if (documentsDetail && documentsDetail.serviceId) {
                const newElement = list.find((x: any) => x.id == documentsDetail.serviceId);
                if (newElement?.id < 0 || newElement == undefined) {
                    setShowServiceWarn(true);
                }
            }
        }

    }, [documentServiceList, documentsDetail])

    function onFilterChange(event?: any, listName?: any) {
        setFilter({ ...filter, [listName]: event.filter });
    }



    const handleSubmit = () => {
        const data = {
            documentId: documentId,
            serviceId: selectedService?.id
        }
        dispatch(updateDocumentService(data))
        onClose()
    }

    return (
        <Dialog
            onClose={onClose}
            title={"Edit Service"}
            className="small-dailog medium-modal width_90 blue_theme" width={"50%"} height="50%"
        >
            <div className="edit-client-popup custom-client-popup">
                <div className="popup-modal slibling-data">
                    <DropDownList
                        // ref={serviceListRef}
                        filterable={true}
                        // data={documentServiceData}
                        data={filterBy(documentServiceData, filter?.documentServiceData)}
                        onFilterChange={(event) => {
                            onFilterChange(event, "documentServiceData");
                        }}
                        // loading={loading.serviceList}
                        textField="name"
                        name="selectedService"
                        ariaDescribedBy="selectedService"
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                        dataItemKey="id"
                        disabled={showServiceWarn}
                    />
                    {/* {renderErrorFor("selectedService")} */}

                    {/* {loading === true && <Loader />} */}
                    {/* {message()} */}
                </div>
                {showServiceWarn && <div className="text-warning">
                    You may not edit this service because you do not have assigned
                    services that match the current service or the current service has
                    been deactivated
                </div>}

                <div className="preview-btn-bottom mt-2">
                    <div className="border-bottom-line"></div>
                    <div className="d-flex my-3">
                        <div className="right-sde">
                            <button
                                className="submitButon "
                                onClick={handleSubmit}
                                disabled={showServiceWarn}
                            >
                                Submit
                            </button>
                        </div>
                        <div className="right-sde-grey">
                            <button
                                className="cancelBtn"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ServiceModel