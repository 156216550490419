import React, { ComponentState, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClientService } from "src/services/clientService";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { NichqHeader } from "./Nichq-heads";
import { QIDSheads } from "./QIDS-heads";
import ClientQuestionnaireService from "./functionToSaveQuestionnaireFromPortal";






export const QIDS_Adolescent = ({ QIDS_A17, QIDS_A17_SCORING, QIDS_A17_HEADERS, response, interfered, isPrint, questionId, isFromCP, queId, token }) => {

  const [qidsAdolescent, setQidsAdolescent] = useState<any[]>([]);
  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const navigate = useNavigate();


  const [qidsAdolescentScoring, setQidsAdolescentScoring] = useState<any[]>([]);
  const [qidsAdolescentHeaders, setQidsAdolescentHeaders] = useState<any[]>([]);


  useEffect(() => {

    const listQidsAdolescent = QIDS_A17.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const QidsAdolescentScoring = QIDS_A17_SCORING.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const QidsAdolescentHeaders = QIDS_A17_HEADERS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });


    setQidsAdolescent(listQidsAdolescent)
    setQidsAdolescentScoring(QidsAdolescentScoring)
    setQidsAdolescentHeaders(QidsAdolescentHeaders)



  }, [response])

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [
        ...qidsAdolescent,
        ...qidsAdolescentScoring,
        ...qidsAdolescentHeaders,
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };

  const handleSaveFromPortal = async () => {
    ClientQuestionnaireService.updatequestionnaireFromPortal(selectedClientId, 0, 
      
      [
        ...qidsAdolescent,
        ...qidsAdolescentScoring,
        ...qidsAdolescentHeaders,
      ],
       queId, token)
  }

  const handleCompleteFromPortal = async () => {
    try {
      ClientQuestionnaireService.completeQuestionnaireFromPortal(false, queId, selectedClientId, 0, [
        ...qidsAdolescent,
        ...qidsAdolescentScoring,
        ...qidsAdolescentHeaders,
      ],  {isCompleted: true, inProgress: false, sentToClientPortal: null}, token)
    } catch (error) {
      console.error('Error completing client questionnaire:', error);
      NotificationManager.error('Failed to complete client questionnaire');
    }
  };

  return (
    <div className="ml-3"> 


      <div className="mt-4 head-4">

        QUICK INVENTORY OF DEPRESSIVE SYMPTOMATOLOGY - ADOLESCENT (17 ITEM) - CLINICIAN RATED
        <br />
        (QIDS-A17-C)
      </div>


      {
        qidsAdolescentHeaders.length > 0 &&
        <div className="row mt-4">
          <NichqHeader

            title={""}
            disabled={isFromCP ? false : (response && response.length>0)}
            subTitle={''}
            listItems={qidsAdolescentHeaders}
            setListItems={setQidsAdolescentHeaders}
          />
        </div>
      }
      {
        qidsAdolescent.length > 0 &&
        <div className="row mt-4 mr-5 ">
          <NichqHeader

            title={""}
            disabled={isFromCP ? false : (response && response.length>0)}
            subTitle={''}
            listItems={qidsAdolescent}
            setListItems={setQidsAdolescent}
          />
        </div>

      }

      <div>
        <label>Scoring Criteria:</label>


        <div className="row">
          <div className="col-md-4"><label>0-5 </label></div>
          <div className="col-md-8"><label>Not Depressed</label> </div>
        </div>
        <div className="row">
          <div className="col-md-4"><label>6-10 </label></div>
          <div className="col-md-8"><label>Mild </label></div>
        </div>
        <div className="row">
          <div className="col-md-4"><label>11-15 </label></div>
          <div className="col-md-8"><label>Moderate</label> </div>
        </div>
        <div className="row">
          <div className="col-md-4"><label>16-20 </label></div>
          <div className="col-md-8"><label>Severe</label> </div>
        </div>
        <div className="row">
          <div className="col-md-4"><label>  21 &#43;</label></div>
          <div className="col-md-8"><label>Very Severe</label> </div>
        </div>
      </div>
      <div className="mt-4">
        <label>To score:</label>
      </div>


      {
        qidsAdolescentScoring.length > 0 &&
        <div className="row mt-4">
          <QIDSheads

            title={""}
            disabled={isFromCP ? false : (response && response.length>0)}
            subTitle={''}
            listItems={qidsAdolescentScoring}
            setListItems={setQidsAdolescentScoring}
          /></div>
      }

{isFromCP ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleCompleteFromPortal} className="submitButon">
            Save & Complete
          </button>
          <button onClick={handleSaveFromPortal} className="cancelBtn">
            Save progress
          </button>
          </div>
        ) : state == null && !isPrint ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleSubmit} className="submitButon">
              Submit
            </button>
          </div>
        ) : null}






    </div>

  )

} 