import React, { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { clientChartModel } from "src/dataModels/docListModel";
import { totalHoursAndMinutes } from "src/util/utility";
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import CustomSkeleton from "src/control-components/skeleton/skeleton";
import { userIsSuperAdmin, userIsAdmin } from "src/helper/permission-helper";
import CommonListFilterItem from "./common-list-filter-item";

const ClientDocumentsChart = () => {
  const state: ComponentState = useSelector((states) => {
    return states;
  });
  const staffLoginInfo = useSelector(
    (state: ComponentState) => state.getStaffReducer
  );
  const isBillingManager = state.getRolePermission.isBillingManager;
  const [chartData, setChartData] = useState<clientChartModel[]>([]);
  const { documentClientChartListResp } = state["DocumentReducer"];
  const showBillingAmounts = state.getRolePermission.showBillingAmounts;
  const [isShowItems, setShowItems] = useState(true);
  const isHumanResourcesManager = useSelector(
    (state: any) => state.getRolePermission?.isHumanResourcesManager
  );
  const userIsSA = userIsSuperAdmin(staffLoginInfo?.roleId);
  const userIsA = userIsAdmin(staffLoginInfo?.roleId);
  const canShowBillData = isBillingManager || userIsSA || showBillingAmounts;
  const canShowTime =
    isBillingManager ||
    userIsSA ||
    showBillingAmounts ||
    isHumanResourcesManager ||
    userIsA;
  // Calculate the number of documents per client member

  useEffect(() => {
    setChartData([]);
    if (
      documentClientChartListResp &&
      documentClientChartListResp?.resultData
    ) {
      // Sort the data by 'totDocs' in descending order
      const sortedData = documentClientChartListResp?.resultData
        ?.slice()
        .sort((a, b) => b.totDocs - a.totDocs);
      sortedData?.map((item) => {
        const minutes = totalHoursAndMinutes(item?.totalMinutes);
        const totalbillValue = Number(item?.totalBilled).toFixed(2);
        const formattedTotalBill = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(totalbillValue));
        const label = `Docs: ${item?.totDocs}, ${
          canShowTime ? "Time:" + " " + minutes : ""
        } , ${
          canShowBillData ? ", Billed Amt:" + " " + formattedTotalBill : ""
        }`;
        var obj: clientChartModel = {
          id:item?.clientId,
          clientName: item?.clientName,
          documents: item?.totDocs,
          info: label,
          time: canShowTime ? minutes : "",
          billingAmount:
          canShowBillData ? formattedTotalBill : "",
        };
        setChartData((oldItem) => [...oldItem, obj]);
      });
    }
    setShowItems(true);
  }, [documentClientChartListResp]);

  // Find the client member with the most documents
  const mostDocumentsClient = chartData[0];
  const mostDocumentsCount = mostDocumentsClient
    ? mostDocumentsClient.documents
    : 0;

  const MyHeader = () => {
    return (
      <ListViewHeader
        style={{
          color: "#000000",
          fontSize: 30,
        }}
        className="px-3 py-2 "
      >
        <div className="row border-bottom align-middle">
          <div className="col-3">
            <h2 className="f-14">Client</h2>
          </div>
          <div className="col-3">
            <h2 className="f-14"># of Docs</h2>
          </div>
          <div className="col-3">
            <h2 className="f-14">Time</h2>
          </div>
          <div className="col-3">
            <h2 className="f-14 ">Billing Charges</h2>
          </div>
        </div>
      </ListViewHeader>
    );
  };
  const MyItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div
        className="row py-2 border-bottom align-middle"
        style={{ margin: 0 }}
      >
        <div className="col-3">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <CommonListFilterItem item={item} type={"client"}/>
          )}
        </div>
        <div className="col-3">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.documents}</div>
          )}
        </div>
        <div className="col-3">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.time}</div>
          )}
        </div>
        <div className="col-3">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.billingAmount}</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {documentClientChartListResp?.resultData?.length != 0 ? (
        <div className="grid-table filter-grid dash-filter-grid my-3 caseload-custom gridheight casload boxHeight traningList topFixHeader">
          <h5>
            {" "}
            Client with most documents
          </h5>
          <ListView
            className="traningList"
            data={chartData}
            item={MyItemRender}
            style={{
              width: "100%",
              display: "block",
            }}
            header={MyHeader}
          />
        </div>
      ) : (
        <p>No Record found</p>
      )}
    </>
  );
};

export default ClientDocumentsChart;
