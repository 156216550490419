import { BillingStatusResultData, ChartResponse, DocumentBillingHistory, DocumentServiceResult, DocumentsData } from "src/dataModels/docListModel";
import {
  SAVE_DRAFT_COUNT, SAVE_AND_REFRESH,
  SAVE_DOC_LIST, SAVE_STAFF_MOST_DOCUMENTS,
  SAVE_CLIENT_MOST_DOCUMENTS,
  SAVE_BILLING_STATUS_METRICS,
  SAVE_CLIENT_LIST,
  GET_DOCUMENT_FILTER, SAVE_SITE_LIST,
  SAVE_STAFF_LIST, SAVE_SERVICE_LIST, SAVE_DOCUMENT_BY_ID,
  SAVE_DOC_BIILING_HISTORY, SELECT_BATCH_ID,
  SELECT_EOB_ID, SAVE_SWITCH_VALUE,
  SAVE_DOCUMENT_PAGE_SIZE,
  SAVE_SELECTED_DOC_ID,
  SAVE_BILLING_ACTION,
  SAVE_DOCUMENT_SERVICES,
  SAVE_EDIT_DOC_DATA,
  SAVE_PLACE_OF_SERVICE,
  SAVE_TEMPLATE_SETTING,
  SUCCESS,
  SAVE_RISK_DDL,
  SET_PREP_PDF_COUNT,
  SET_EXPORT_PDF_COUNT,
  SAVE_LATEST_DOCUMENT_BY_TEMPLATE_ID,
  SAVE_RISK_FACTOR_DDL,
  SAVE_RISK_PROTECTIVE_DDL,
  SAVE_SWITCH_STAFF_VALUE,
  SAVE_DATA_FOR_AUTO_POPULATE_IN_TEMP,
  SAVE_BILLING_BY_PAYERS_METRICS,
  LOCK_UNLOCK_DOCUMENTS,
  APPROVE_DISAPPROVE_DOCUMENTS,
  SAVE_APPROVE_DISAPPROVE_DOCUMENTS,
  SAVE_METRICS_BY_DOC_STATUS,
  SAVE_DOC_METRICS_BY_SERVICE_CODE,
  SAVE_METRICS_BY_TEMPLATE,
  SAVE_CLIENT_DOC_SUPER_BILL,
  SAVE_DOCUMENT_TEMPLATE_TYPE,
  SAVE_DOCUMENT_ATTACHMENT,
  DOC_UNITS_CHANGED,
  SAVE_MILEAGE_PAY_RATE_BY_STAFF_ID,
  SAVE_CLIENTS_MEDICATION,
  SAVE_CLIENTS_PRINTED_DOCUMENT,
  SAVE_INITIATE_DOC_MODEL,
  SUCCESS_SERVICE_UPDATE,
  SUCCESS_POS_UPDATE,
  SUCCESS_DX_UPDATE
} from "../actions/types";

import { AdvanceSearchObject, DocumentResponseObj, DropDownObjectWithNumber, DropDownObjectWithString } from "src/dataModels/docListModel";

const initialState = {
  draftCount: 0,
  documentOperationResponse: {},
  documentResponse: {} as DocumentResponseObj,
  documentStaffChartListResp: {} as ChartResponse,
  documentClientChartListResp: {} as ChartResponse,
  documentBillingChartListResp: {} as ChartResponse,
  documentSearchObject: {} as AdvanceSearchObject,
  documentBillingByPayerResp: {} as ChartResponse,
  clientList: null as DropDownObjectWithString[] | null,
  servicesList: null as DropDownObjectWithString[] | null,
  sitesList: null as DropDownObjectWithString[] | null,
  staffsList: null as DropDownObjectWithNumber[] | null,
  documentsDetail: null as DocumentsData | null,
  documentBillingHistory: null as DocumentBillingHistory | null,
  batchId: 0 as number,
  eobId: 0 as number,
  documentPageSize: 0 as number,
  serviceLineDocId: 0 as number,
  billingStatus: null as BillingStatusResultData[] | null,
  documentServiceList: null as DocumentServiceResult[] | null,
  docPreparedCount: 0 as number,
  docExportCount: 0 as number,
  documentDescriptionForAutoPopulate: null as string | null,
  selectedDocumentsIds: {},
  documentIdsGettingApproved: {},
  approveDisapproveResponse: -1,
  documentBillingByTemplate: {} as ChartResponse,
  documentBillingByStatus: {} as ChartResponse,
  documentByServiceCode: {} as ChartResponse,
  superBillData: {} as any,
  docTempTypes: [],
  docAttachments: [],
  docUnitsChanged: false,
  mileagePayRate: null,
  clientsMedicationResp: [],
  clientPrintedDocumentsResp: [],
  initiateDocModel: {} as any,

};

export const DocumentReducer = (state = initialState, action: any) => {
  const oldState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case SAVE_DRAFT_COUNT:
      return {
        ...oldState,
        draftCount: payload,
      };

    case SAVE_AND_REFRESH:
      return {
        ...oldState,
        documentOperationResponse: payload,
      };

    case SAVE_DOC_LIST:
      return {
        ...oldState,
        documentResponse: payload,
      };
    case SAVE_STAFF_MOST_DOCUMENTS:
      return {
        ...oldState,
        documentStaffChartListResp: payload,
      };
    case SAVE_CLIENT_MOST_DOCUMENTS:
      return {
        ...oldState,
        documentClientChartListResp: payload
      }
    case SAVE_BILLING_STATUS_METRICS:
      return {
        ...oldState,
        documentBillingChartListResp: payload
      }
    case SAVE_BILLING_BY_PAYERS_METRICS:
      return {
        ...oldState,
        documentBillingByPayerResp: payload
      }

    case GET_DOCUMENT_FILTER:
      return {
        ...oldState,
        documentSearchObject: payload,
      };

    case SAVE_CLIENT_LIST:
      return {
        ...oldState,
        clientList: payload,
      };

    case SAVE_SITE_LIST:
      return {
        ...oldState,
        sitesList: payload,
      };

    case SAVE_STAFF_LIST:
      return {
        ...oldState,
        staffsList: payload,
      };

    case SAVE_SERVICE_LIST:
      return {
        ...oldState,
        servicesList: payload,
      };

    case SAVE_DOCUMENT_BY_ID:
      return {
        ...oldState,
        documentsDetail: payload,
      };

    case SAVE_DOC_BIILING_HISTORY:
      return {
        ...oldState,
        documentBillingHistory: payload,
      };

    case SELECT_BATCH_ID:
      return {
        ...oldState,
        batchId: payload,
      };

    case SELECT_EOB_ID:
      return {
        ...oldState,
        eobId: payload,
      };




    case SAVE_DOCUMENT_PAGE_SIZE:
      return {
        ...oldState,
        documentPageSize: payload,
      };



    case SAVE_SWITCH_VALUE:
      return {
        ...oldState,
        switchValue: payload,
      };
    case SAVE_SWITCH_STAFF_VALUE:
      return {
        ...oldState,
        switchStaffValue: payload,
      };

    case SAVE_SELECTED_DOC_ID:
      return {
        ...oldState,
        serviceLineDocId: payload,
      };
    case SAVE_BILLING_ACTION:
      return {
        ...oldState,
        billingStatus: payload,
      };
    case SAVE_DOCUMENT_SERVICES:
      return {
        ...oldState,
        documentServiceList: payload,
      };
    case SAVE_EDIT_DOC_DATA:
      return {
        ...oldState,
        editDocData: payload,
      };
    case SAVE_PLACE_OF_SERVICE:
      return {
        ...oldState,
        POSlist: payload,
      };
    case SAVE_TEMPLATE_SETTING:
      return {
        ...oldState,
        templateSetting: payload,
      };
    case SUCCESS:
      return {
        ...oldState,
        success: payload,
      };
    case SUCCESS_SERVICE_UPDATE:
      return {
        ...oldState,
        successResponseServiceUpdate: payload,
      };
    case SUCCESS_POS_UPDATE:
      return {
        ...oldState,
        successResponsePOSUpdate: payload,
      };
    case SUCCESS_DX_UPDATE:
      return {
        ...oldState,
        successResponseDxUpdate: payload,
      };
    case SAVE_RISK_DDL:
      return {
        ...oldState,
        riskList: payload,
      };
    case SAVE_RISK_FACTOR_DDL:
      return {
        ...oldState,
        riskFactorList: payload,
      };
    case SAVE_RISK_PROTECTIVE_DDL:
      return {
        ...oldState,
        riskProtectiveList: payload,
      };
    case SET_PREP_PDF_COUNT:

      return {
        ...oldState,
        docPreparedCount: payload,
      };

    case SET_EXPORT_PDF_COUNT:
      return {
        ...oldState,
        docExportCount: payload,
      };
    case SAVE_LATEST_DOCUMENT_BY_TEMPLATE_ID:
      return {
        ...oldState,
        latestDocumentTemplateId: payload,
      };
    case SAVE_DATA_FOR_AUTO_POPULATE_IN_TEMP:
      return {
        ...oldState,
        documentDescriptionForAutoPopulate: payload,
      };
    case LOCK_UNLOCK_DOCUMENTS:
      return {
        ...oldState,
        selectedDocumentsIds: payload
      }
    case APPROVE_DISAPPROVE_DOCUMENTS:
      return {
        ...oldState,
        documentIdsGettingApproved: payload
      }

    case SAVE_APPROVE_DISAPPROVE_DOCUMENTS:
      return {
        ...oldState,
        approveDisapproveResponse: payload,
      }

    case SAVE_METRICS_BY_TEMPLATE:
      return {
        ...oldState,
        documentBillingByTemplate: payload
      }

    case SAVE_METRICS_BY_DOC_STATUS:
      return {
        ...oldState,
        documentBillingByStatus: payload
      }
    case SAVE_DOC_METRICS_BY_SERVICE_CODE:
      return {
        ...oldState,
        documentByServiceCode: payload
      }

    case SAVE_CLIENT_DOC_SUPER_BILL:

      return {
        ...oldState,
        superBillData: payload
      }


    case SAVE_DOCUMENT_TEMPLATE_TYPE:
      return {
        ...oldState,
        docTempTypes: payload
      }

    case SAVE_DOCUMENT_ATTACHMENT:
      return {
        ...oldState,
        docAttachments: payload
      }
    case DOC_UNITS_CHANGED:
      return {
        docUnitsChanged: payload
      }
    case SAVE_MILEAGE_PAY_RATE_BY_STAFF_ID:
      return {
        mileagePayRate: payload
      }

    case SAVE_CLIENTS_MEDICATION:
      return {
        clientsMedicationResp: payload
      }

    case SAVE_CLIENTS_PRINTED_DOCUMENT:
      return {
        clientPrintedDocumentsResp: payload
      }

    case SAVE_INITIATE_DOC_MODEL:
      return {
        initiateDocModel: payload
      }
  }
  return oldState;
};
