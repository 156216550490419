import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { AppSuccessResponse } from 'src/dataModels/apiSuccessResponse';
import { saveReportLoading, saveClientGeneralReport} from '../actions';
import { customAxios } from 'src/services/useExPressApi';
import { API_ENDPOINTS } from 'src/services/api-endpoints';



export function* getClientGeneralReportsSaga() {
    yield takeLatest(TYPES.GET_CLIENT_GENERAL_REPORT, clientReportWorker);
}

function* clientReportWorker(param: any) {
    try {
        yield put(saveReportLoading(true));
        const response: AppSuccessResponse = yield  customAxios.post<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_CLIENT_GENERAL_REPORT}`,param['payload']);
        yield put(saveClientGeneralReport(response));
        yield put(saveReportLoading(false));
    } catch (err) {
    }
}

export function* getClientServiceRuleReportsSaga() {
    yield takeLatest(TYPES.GET_CLIENT_SERVICE_RULE_REPORT, ruleReportWorker);
}

function* ruleReportWorker(param: any) {
    try {
        yield put(saveReportLoading(true));
        const response: AppSuccessResponse = yield  customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_CLIENT_SERVICE_RULE_REPORT}?serviceDate=${param['payload']}`,{});
        yield put(saveClientGeneralReport(response));
        yield put(saveReportLoading(false));
    } catch (err) {
    }
}


